<template>
  <div>
    <div class="box" v-show="markes">
      <img
        @click="close"
        class="cha"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="box-main">
        <div class="main-nav">
          <h1>人员奖励</h1>
        </div>
        <!-- 帐号 -->
        <div class="accounts">
          <label class="accounts-label" for="">帐号</label>
          <input class="accounts-input" type="text"  v-model="detail.username"  disabled />
        </div>
        <!-- 姓名 -->
        <div class="name">
          <label class="name-label" for="">姓名</label>
          <input class="name-input" type="text" v-model="detail.name"/>
        </div>
        <!-- 信息 -->
        <div class="message">
          <label class="message-label" for="">微信信息</label>
          <div class="message-main" v-if="detail.is_relate == 1">
            <img :src="detail.relate_img" alt="" />
            <span>{{detail.relate_name}}</span>
            <br />
            <span>{{detail.relate_username}}</span>
          </div>
          <div class="message-main" v-if="detail.is_relate == 0">
             （无）
          </div>
        </div>
        <!-- 方案 -->
        <div class="scheme">
          <div class="xing2">*</div>
          <label class="scheme-label" for="">方案</label>
          <el-radio v-model="radio1" label="1" size="large">每单</el-radio>
          <el-radio v-model="radio1" label="2" size="large">百分比</el-radio>
        </div>
        <!-- 价格 -->
        <div class="price">
          <input class="price-input" type="text"   v-model="value1" />
          <span class="price-span">元/单</span>
          <input class="price-input1" type="text"  v-model="value2" />
          <span class="price-span1">百分比</span>
        </div>

        <div class="btn">
          <button class="set" @click="commission(detail.uid)">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import * as api from "../api/join.js";

const radio1 = ref("1");
export default {
  props:["ismarke",'marketinguid'],
  data() {
    return {
      radio1,
      markes:this.ismarke,
      detail:{},
      value1:'',
      value2:'',
    };
  },
  methods: {
    close() {
      this.markes = false;
    },
     async commission(uid){

        if(this.radio1 == 1){
            if(this.value1 == '' || this.value1 == 0 ){
              this.$message.warning('请输入每单提成价');
              return;
            }

            let res = await api.commission(uid,this.radio1,this.value1);
            if(res.code == 1)
            {
              this.$message.success('成功');
              return;
            }else{
              this.$message.warning(res.message);
              return;
            }
        }else{
            if(this.value2 == '' || this.value2 == 0 ){
              this.$message.warning('请输入每单提成百分比');
              return;
            }
            if(this.value2 > 100)
            {
              this.$message.warning('每单百分比不能超过100');
              return;
            }
            let res = await api.commission(uid,this.radio1,this.value2);
            if(res.code == 1)
            {
              this.$message.success('成功');
              return;
            }else{
              this.$message.warning(res.message);
              return;
            }
        }



    }

  },
  watch:{
    ismarke(newval){
      console.log('---',newval);
      this.markes = newval
    },
    async marketinguid(newval){
      console.log(newval);
      let res = await api.manageMarketing(newval);
      this.detail = res.data;
      console.log(this.detail);

    },
   
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 55;
  //   background-color: pink;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 260px;
  height: 26px;
  padding-top: 21px;
  padding-left: 30px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}

.accounts,
.name,
.message,
.scheme {
  width: 450px;
  height: 40px;
  margin-top: 10px;
  //   background-color: skyblue;
}
.scheme {
  margin-left: 38px;
}
.message-main {
  width: 300px;
  margin-left: 86px;
}
img {
  float: left;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-right: 10px;
}
span {
  height: 19px;
  font-size: 14px;
  margin-top: 5px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.message-label {
  float: left;
}
.scheme-label {
  margin-right: 48px;
}
.name {
  margin-left: 43px;
}
.message {
  margin-left: 38px;
}
.accounts-label,
.name-label {
  width: 260px;
  height: 40px;
  margin-right: 48px;
  opacity: 1;
  border-radius: 2px;
}
.accounts-input,
.name-input {
  width: 260px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
}
.accounts-input:hover{
  border: 1px solid #c0c4cc;
}
.name-input:hover{
  border: 1px solid #c0c4cc;
}
.xing,
.xing1,
.xing2 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 18px;
}
.xing1 {
  margin-top: 10px;
}
.xing2 {
  margin-top: 20px;
}

.price {
  margin-left: 115px;
}
.price-input,
.price-input1 {
  float: left;

  height: 40px;
  padding-left: 8px;
  margin-top: 22px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  box-sizing: border-box;
}
.price-input {
  width: 90px;
}
.price-input1 {
  width: 100px;
  margin-left: 20px;
}
.price-span,
.price-span1 {
  float: left;
  height: 40px;
  text-align: center;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  box-sizing: border-box;
}
.price-span {
  width: 44px;
  line-height: 40px;
  margin-top: 22px;
  margin-left: -44px;
}
.price-span1 {
  width: 54px;
  margin-top: 22px;
  margin-left: -54px;
  line-height: 40px;
}

.btn {
  float: left;
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 115px;
  margin-top: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
