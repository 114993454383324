<template>
  <div class="examBox" v-if="shilis">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="exammain">
      <h1>示例照片</h1>
      <!-- 现场照 -->
      <el-checkbox-group v-model="p1" @change="checkChange" :max="16">
        <div class="owner">
          <span class="state-span">现场照</span>
          <!-- 现场照 -->
          <div class="car-photo">
            <div class="car-p1" v-for="(item, index) in mould1" :key="index">
              <el-image
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index"
                fit="cover"
                @click="clickimg(item.src)"
              >
              </el-image>
              <div class="owBottom">
                <el-checkbox-button
                  id="btn"
                  :key="item.type"
                  :label="item.type"
                  @change="xuan(index)"
                >
                  {{ item.name }}
                </el-checkbox-button>
              </div>
              <div class="shunxu" v-if="idx.indexOf(index) > -1">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </el-checkbox-group>
      <el-checkbox-group v-model="p2" @change="checkChange" :max="8">
        <div class="owner1">
          <span class="state-span">服务照</span>
          <!-- 现场照 -->
          <div class="car-photo">
            <div class="car-p1" v-for="(item, index) in mould2" :key="index">
              <el-image
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index + mould1.length"
                fit="cover"
                @click="clickimg(item.src)"
              >
              </el-image>

              <div class="owBottom">
                <el-checkbox-button
                  id="btn"
                  :key="item.type"
                  :label="item.type"
                  @change="xuan2(index)"
                >
                  {{ item.name }}
                </el-checkbox-button>
              </div>
              <div class="shunxu" v-if="idx2.indexOf(index) > -1">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </el-checkbox-group>

      <el-checkbox-group v-model="p3" @change="checkChange" :max="8">
        <div class="owner1">
          <span class="state-span">完成照</span>
          <!-- 现场照 -->
          <div class="car-photo car-photo1">
            <div class="car-p1" v-for="(item, index) in mould3" :key="index">
              <el-image
                :src="item.src"
                :preview-src-list="srcList"
                :initial-index="index + mould1.length + mould2.length"
                fit="cover"
                @click="clickimg(item.src)"
              >
              </el-image>
              <div class="owBottom">
                <el-checkbox-button
                  id="btn"
                  :key="item.type"
                  :label="item.type"
                  @change="xuan3(index)"
                >
                  {{ item.name }}
                </el-checkbox-button>
              </div>
              <div class="shunxu" v-if="idx3.indexOf(index) > -1">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </el-checkbox-group>
      <div class="btn">
        <button class="set" @click="update">确定</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/index";
export default {
  props: ["isshili", "exrid", "exccid"],
  data() {
    return {
      shilis: this.isshili,
      mould1: [],
      mould2: [],
      mould3: [],
      p1: [],
      p2: [],
      p3: [],
      mouldall: [],
      rescue_id: this.exrid,
      srcList: [],
      arr: [],
      arr2: [],
      arr3: [],
      idx: [],
      idx2: [],
      idx3: [],
    };
  },
  methods: {
    xuan(index) {
      let arrlndex = this.idx.indexOf(index);
      if (arrlndex > -1) {
        this.idx.splice(arrlndex, 1);
      } else {
        this.idx.push(index);
      }
      if (this.mould1[index].value == undefined) {
        this.arr.push(this.mould1[index]);
        this.mould1[index].value = this.arr.length;
        //console.log("选中", this.mould1[index], this.arr);
      } else {
        var i;
        this.arr.map((item, indexs) => {
          if (item == this.mould1[index]) {
            i = indexs;
          }
        });
        this.mould1.map((item) => {
          if (i + 1 < item.value) {
            item.value = item.value - 1;
          }
        });

        this.mould1[index].value = undefined;
        this.arr.splice(i, 1);
      }
    },
    xuan3(index) {
      let arrlndex = this.idx3.indexOf(index);
      if (arrlndex > -1) {
        this.idx3.splice(arrlndex, 1);
      } else {
        this.idx3.push(index);
      }
      if (this.mould3[index].value == undefined) {
        this.arr3.push(this.mould3[index]);
        this.mould3[index].value = this.arr3.length;
        // console.log("选中", this.mould3[index], this.arr3);
      } else {
        var i;
        this.arr3.map((item, indexs) => {
          if (item == this.mould3[index]) {
            i = indexs;
          }
        });
        this.mould3.map((item) => {
          if (i + 1 < item.value) {
            item.value = item.value - 1;
          }
        });

        this.mould3[index].value = undefined;
        this.arr3.splice(i, 1);
      }
    },
    xuan2(index) {
      let arrlndex = this.idx2.indexOf(index);
      if (arrlndex > -1) {
        this.idx2.splice(arrlndex, 1);
      } else {
        this.idx2.push(index);
      }
      if (this.mould2[index].value == undefined) {
        this.arr2.push(this.mould2[index]);
        this.mould2[index].value = this.arr2.length;
        // console.log("选中", this.mould2[index], this.arr2);
      } else {
        var i;
        this.arr2.map((item, indexs) => {
          if (item == this.mould2[index]) {
            i = indexs;
          }
        });
        this.mould2.map((item) => {
          if (i + 1 < item.value) {
            item.value = item.value - 1;
          }
        });

        // console.log('--',this.celist);
        this.mould2[index].value = undefined;
        this.arr2.splice(i, 1);
      }
    },
    
    close() {
      this.shilis = false;
      this.idx = [];
      this.idx2 = [];
      this.idx3= [];
      this.arr = [];
      this.arr2 = [];
      this.arr3 = [];
      //this.rescue_id ='';
      this.mould1 = [];
      this.mould2 = [];
      this.mould3 = [];
      this.mouldall = [];
      this.p1 = [];
      this.p2 = [];
      this.p3 = [];
    },
    clickimg() {},
    async getshow() {
      let res = await api.getshowmouldx(this.rescue_id, this.exccid);
      this.mould1 = res.data.mould1;
      this.mould2 = res.data.mould2;
      this.mould3 = res.data.mould3;
      this.mouldall = res.data.mouldall;
      this.p1 = res.data.p1;
      this.p2 = res.data.p2;
      this.p3 = res.data.p3;

      console.log(this.mould3);

      this.idx = [];
      this.idx2 = [];
      this.idx3= [];
      this.arr = [];
      this.arr2 = [];
      this.arr3 = [];


      ["1", "2", "3"].forEach((x) => {
        if (this[`p${x}`].length == 0) {
        } else {
          let indexArr = this[`p${x}`].map((ele) => {
            return this[`mould${x}`].findIndex((item) => {
              return item.type == ele;
            });
          });
          indexArr.forEach((item) => {
            if(item != -1)
            {
              this[`xuan${x == 1 ? "" : x}`](item);
            }

          });

        }
      });

      console.log(this.arr3);

      let newarr = Object.values(this.mouldall);
      //console.log("示例照片", newarr);
      this.srcList = newarr.map((item, index, arr) => {
        return item.src.replace("png", "jpg");
      });
    },
    async update() {
      if (this.p1.length == 0) {
        this.$message.warning("现场照模板最少一张");
        return false;
      }
      let md1 = "";
      let md2 = "";
      let md3 = "";
      for (let i = 0; i < this.p1.length; i++) {
        if (i == 0) {
          md1 += this.p1[i];
        } else {
          md1 += "," + this.p1[i];
        }
      }
      if (this.p2.length == 0) {
        this.$message.warning("服务照模板最少一张");
        return false;
      }
      for (let i = 0; i < this.p2.length; i++) {
        if (i == 0) {
          md2 += this.p2[i];
        } else {
          md2 += "," + this.p2[i];
        }
      }
      if (this.arr3.length == 0) {
        this.$message.warning("完成照模板最少一张");
        return false;
      }
      for (let i = 0; i < this.arr3.length; i++) {

        
        if (i == 0) {
          md3 += this.arr3[i].type;
        } else {
          md3 += "," + this.arr3[i].type;
        }
      }

      let res = await api.updatemouldx(
        this.rescue_id,
        md1,
        md2,
        md3,
        this.exccid
      );

      if (res.code == 1) {
        this.$message.success("成功");
        this.close();
      }
    },
  },
  watch: {
    isshili(newval) {
      this.shilis = newval;
      console.log('救援类型',this.rescue_id);
      console.log('传进来类型',this.exrid);
      if (this.rescue_id != "" && this.rescue_id == this.exrid) {
        this.getshow();
      }
    },
    exrid(newval) {
      console.log(newval);
      this.rescue_id = newval;
      this.getshow();
    },
    p1(newval) {
      if (newval.length == 16) {
        this.$message.warning("现场照最大模板只能为16张");
      }
    },
    p2(newval) {
      if (newval.length == 8) {
        this.$message.warning("服务照最大模板只能为8张");
      }
    },
    p3(newval) {
      if (newval.length == 8) {
        this.$message.warning("完成照最大模板只能为8张");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.examBox {
  width: 700px;
  height: 920px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
.exammain {
  width: 700px;
  height: 920px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  overflow-y: scroll;
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
h1 {
  padding-top: 20px;
  padding-left: 30px;
  //   height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  box-sizing: border-box;
  opacity: 1;
}
.owner,
.owner1 {
  display: flex;
}
.owner {
  margin-left: 30px;
}
.owner1 {
  margin-top: 20px;
  margin-left: 34px;
}
.state-span,
.state-span1 {
  margin-right: 12px;
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}

.car-photo {
  width: 694px;
  border-bottom: 1px dashed #d9d9d9;
  margin-bottom: 20px;
}
.xian {
  border: 0;
}
.car-p1 {
  float: left;
  margin-right: 17px;
  padding-bottom: 20px;
  position: relative;
}
.car-photo /deep/ .el-image {
  width: 120px;
  height: 100px;
}

.car-p1 /deep/.el-checkbox-button__inner {
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  overflow: hidden;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 90px;
  margin-top: 50px;
  padding-bottom: 50px;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.shunxu {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 30px;
  background-color: red;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
}
</style>