<template>
  <div class="box">
    <div class="nav-box">
      <div class="mt-4">
        <!-- 业务员 -->
        <div class="zhong">
          <el-select
            filterable
            class="input-unit"
            v-model="salesman"
            placeholder="业务员"
            @change="changesaleman"
          >
            <el-option
              v-for="item in salelist"
              :key="item.uid"
              :label="item.nickname"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </div>
        <div class="zhong">
          <el-select
            filterable
            class="input-unit"
            v-model="companytype"
            placeholder="公司类型"
            @change="changecompanytype"
          >
            <el-option
              v-for="item in companytypelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <el-input
          v-model="keyword"
          placeholder="请输入搜索内容"
          class="input-with-select"
          @keyup.enter="search"
        >
          <template #append>
            <el-button
              class="iconfont icon-fangdajing"
              @click="search"
            ></el-button>
          </template>
        </el-input>
      </div>

      <!-- 右边添加 -->
      <div @click="screen" class="screen" v-if="!getlook">
        <img class="screen-img" src="../assets/img/ding/tianjia.png" alt="" />
        <p class="screen-p">添加</p>
      </div>
      <div class="export" @click="export1" v-if="canExport && !getlook">
        <img class="export-img" src="../assets/img/ding/daochu.png" alt="" />
        <p class="export-p">导出</p>
      </div>
      <div class="export" @click="dl" v-if="billZip && !getlook">
        <img class="export-img" src="../assets/img/ding/daochu.png" alt="" />
        <p class="export-p">账单</p>
      </div>
      <div class="export">
        <el-checkbox-button
          v-model="checked1"
          :label="recycle"
          size="large"
          @change="state"
        />
      </div>
      <div @click="shujubtn" class="export2"  v-if="looksj && !getlook">
        <img class="screen-img" src="../assets/img/index/shuju.png" alt="" />
        <p class="screen-p">数据</p>
      </div>
    </div>
    <append :issite="site" @datas="add"></append>
    <exception v-if="shujuyin" @guanbi="guanbi"></exception>
    <div class="main">
      <teamwork
        :isState="isState"
        :isfresh="refreshss"
        :bkeyword="bkeywords"
        :bsaleman="bsalemans"
        :bcompanytype="bcompanytypes"
      ></teamwork>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import teamwork from "../components/teamwork.vue";
import Append from "../components/append.vue";
import * as api from "../api/index";
import * as api1 from "../api/order";
import { ElLoading } from "element-plus";
import Cookies from "js-cookie";
import { ElMessageBox } from "element-plus";
import Exception from '../components/exception.vue';
let timeoverBill = 0;
let timerBill;
export default {
  components: { teamwork, Append,Exception },
  data() {
    return {
      keyword: "",
      bkeywords: "",
      site: 0,
      com: true,
      refreshss: 0,
      salesman: "",
      bsalesmans: "",
      salelist: [],
      companytype: "",
      bcompanytypes: "",
      companytypelist: [],

      loadingInstance: null,
      canExport: false,

      recycle: "回收站",
      checked1: false,
      isState: 2,
      timeoverBill,
      timerBill,
      billZip:false,
      shujuyin:false,
      looksj:false
    };
  },
  async created() {
    let res = await api.getsaleslista();
    this.salelist = res.data;
    this.canExport = res.spExport;
    this.billZip = res.billZip;
    this.looksj = res.looksj
    let res1 = await api.getcompanytypea();
    this.companytypelist = res1.data;
  },
  methods: {
    screen() {
      this.site++;
      if (this.site == 3) {
        this.site = 1;
      }
      // this.site = !this.site;
    },
    search() {
      this.bkeywords = this.keyword;
    },
    state(e) {
      if (e) {
        this.isState = 1;
      } else {
        this.isState = 2;
      }
    },
    add(hzshua) {
      this.refreshss++;
    },
    changesaleman() {
      console.log(this.salesman);
      this.bsalemans = this.salesman;
    },
    changecompanytype() {
      this.bcompanytypes = this.companytype;
    },
    async dl()
    {
      if(timeoverBill > 0)
      {
        this.$message.warning("距离上次导出倒计时还有 "+timeoverBill+'秒');
        return false;
      }
      let data = new Date();
      let year = data.getFullYear();
      let month = data.getMonth();

      if(month == 0)
      {
        year = year - 1;
        month = 12; 
      }else if( month > 0  && month < 10)
      {
        month = '0'+month;
      }

      let time = year+'-'+month;

      let uid = Cookies.get("ntwl-uid");

      ElMessageBox.confirm("确认是否打包导出上个月账单,请耐心等待2分钟", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open('https://www-test.nituowola.com/site/x11062?uid='+uid+'&time='+time,'_blank');
          timeoverBill = 120;
          timerBill=setInterval(function(){
            timeoverBill--;
                console.log(timeoverBill);
              
                if (timeoverBill == 0) {
                    clearInterval(timerBill);
                } 
                },1000)
          })
        .catch(() => {
          this.$message.info("取消导出");
        });
 
    },
    export1() {
      // let newdata = {
      //   keyword: this.keyword,
      //   saleman: this.salesman,
      //   companytype: this.companytype,
      // };
      // let res = await api1.SpListExport(newdata);

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let newdata = {
                keyword: this.keyword,
                saleman: this.salesman,
                companytype: this.companytype,
                status:this.isState
              };
              let res = await api1.SpListExport(newdata);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("导出成功请保存");
        })
        .catch(() => {
          this.$message.warning("取消导出");
        });
    },
    shujubtn() {
      this.shujuyin = true
    },
    guanbi() {
      this.shujuyin = false
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getlook() {
      return this.$store.state.lookonly;
    },
  },
};
</script>

<style lang="less" scoped>
.mt-4 {
  float: left;
  // width: 300px;
  height: 40px;
  // margin-left: 60px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.box {
  box-sizing: border-box;
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 193px;
  margin-top: 0px;
  padding: 30px;
  background-color: #f8fafc;
  position: relative;
}
.nav-box {
  width: 1670px;
  height: 120px;
  background-color: #fff;
  overflow: hidden;
  padding-left: 30px;
  box-sizing: border-box;
}
.left {
  float: left;
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 850px;
  border-radius: 2px;
  position: relative;
}
span {
  float: left;
  width: 42px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.xian {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 14px;
  margin-right: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 60px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.flush {
  float: left;
  width: 130px;
  height: 50px;
  margin-left: 30px;
  margin-top: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
}
.export {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}

.export /deep/ .el-checkbox-button__inner {
  width: 130px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.export /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #2c68ff;
  box-shadow: none !important;
  border-radius: 0;
}

.screen-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
}
.screen-p {
  float: left;
  margin-top: 27px;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.export-img,
.flush-img {
  float: left;
  width: 26px;
  height: 26px;
  margin-right: 16px;
  margin-left: 27px;
  margin-top: 12px;
}
.export-p,
.flush-p {
  float: left;
  width: 32px;
  height: 22px;
  font-size: 16px;
  margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}
input {
  float: left;
  width: 70px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  border: none;
  outline: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #9ea3b9;
  opacity: 1;
}
.jing {
  float: left;
  width: 40px;
  height: 40px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
}
.icon {
  width: 14px;
  height: 14px;
  margin-left: 13px;
  margin-top: 13px;
  //   border: 2px solrgb(27, 28, 29)e96;
  border-radius: 50%;
  opacity: 1;
}
.main {
  width: 1670px;
  height: 670px;
  padding-left: 30px;
  box-sizing: border-box;
  background-color: #fff;
}

// .mt-4 /deep/ .el-input__inner{
//   width: 300px;
//   height: 40px;
//   margin-left: 20px;
// }
// .mt-4 /deep/ .el-input-group__append{
//   height: 40px;
// }
.zhong /deep/ .el-select .el-input {
  width: 200px;
  margin-right: 20px;
}


.export2{
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 30px;
  border: 1px solid #29CC8C;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
  cursor: pointer;
  background: #29CC8C;
}
</style>
