<template>
  <div>
    <div class="box" v-show="rates">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>服务价格</h1>
        </div>
        <!-- 合作单位 -->
        <div class="name">
          <div class="xing2">*</div>
          <label class="label1" for="">合作单位</label>
          <input type="text" readonly="readonly" :value="detailP.spName" />
        </div>
        <!-- 服务项目 -->
        <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">服务项目</label>
          <el-select
            v-model="rescueType"
            class="el-select"
            :placeholder="detailP.rescueName"
            @change="selected"
          >
            <el-option
              v-for="item in detailP.rescueTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 价格设置 -->
        <div class="name1">
          <label class="label2" for="">价格设置</label>

          <el-radio
            v-model="chargeType"
            label="1"
            size="large"
            @change="funGong"
            >一口价</el-radio
          >
          <el-radio
            v-model="chargeType"
            label="2"
            size="large"
            @change="funGong"
            >公里价</el-radio
          >
        </div>

        <div class="priceelect">
          <div class="priceelect1">
            <input class="priceelect-span1" id="a" :placeholder="jizhun" @keyup.enter="add"/>
            <span
              class="priceelect-span2"
              v-if="rescueType != 12 && rescueType != 13"
              >元</span
            >
            <span class="priceelect-span2" v-if="rescueType == 12">元</span>
            <span class="priceelect-span2" v-if="rescueType == 13">元</span>
          </div>

          <div class="priceelect2" v-if="showYiKou">
            <input class="priceelect-span1" id="b" :placeholder="juli" @keyup.enter="add"/>
            <span
              class="priceelect-span3"
              v-if="rescueType != 12 && rescueType != 13"
              >公里</span
            >
            <span class="priceelect-span3" v-if="rescueType == 12">层</span>
            <span class="priceelect-span3" v-if="rescueType == 13">个数</span>
          </div>
          <div class="priceelect3" v-if="showYiKou">
            <input class="priceelect-span1" id="c" :placeholder="jia" @keyup.enter="add"/>
            <span
              class="priceelect-span4"
              v-if="rescueType != 12 && rescueType != 13"
              >元/公里</span
            >
            <span class="priceelect-span4" v-if="rescueType == 12">元/层</span>
            <span class="priceelect-span4" v-if="rescueType == 13">元/个数</span>
          </div>
          <div class="priceelect4" v-if="showYiKou" >
            <input class="priceelect-span1" id="d" placeholder="免费" @keyup.enter="add"/>
            <span
              class="priceelect-span5"
              v-if="rescueType != 12 && rescueType != 13"
              >公里</span
            >
            <span class="priceelect-span5" v-if="rescueType == 12">层</span>
            <span class="priceelect-span5" v-if="rescueType == 13">个数</span>
          </div>
           <div class="priceelect4" v-if="showYiKou && iscarprice == 1 && (rescueType == 1)" >
             <el-select
            v-model="car_type"
            class="el-select"
            placeholder="请选择对应车型"
         
          >
            <el-option
              v-for="item in cartypelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          </div>
          <div class="priceelect4" v-if="showYiKou && !(rescueType == 1 || rescueType == 2 || rescueType ==  19)" >
            <input
            class="priceelect-span6"
            type="text"
            placeholder="最高价"
            v-model="maxPrice"
          /><span class="priceelect-span7">元<img class="icon" src="../assets/img/cd/ti.png" alt="" style="width:15px; margin-right: 5px; margin-left:5px;"/>不填默认无上限</span>
          </div>
          <img
            class="add"
            src="../assets/img/slices5/price-icon-addbtn.png"
            @click="add"
            alt=""
          />
        </div>
        <br />
        <!-- 价格输入内容 -->
        <div class="item-box">
          <ul>
            <li
              v-for="(item, index) in listArr"
              :key="index"
              v-on:click="selectid(item.id)"
            >
              {{ `${item.x}  ${item.a}  ${item.b}  ${item.c}  ${item.d} ${item.e}` }}

              <button @click="delprice(item.id)">删除</button>
            </li>
          </ul>

          <img
            class="minus"
            src="../assets/img/slices5/price-icon-minus-btn.png"
            @click="jian"
            alt=""
          />
        </div>

        <!-- 收费类型 -->
        <div class="name2">
          <div class="xing1">*</div>
          <label class="label1" for="">默认收费类型</label>
          <el-radio v-model="isGua" label="1" size="large">挂账</el-radio>
          <el-radio v-model="isGua" label="0" size="large">现金</el-radio>
        </div>
        <!-- 夜间价格 -->
        <div class="name2">
          <div class="xing1">*</div>
          <label class="label1" for="">开启夜间价格</label>
          <el-radio
            v-model="isNightPrice"
            label="1"
            size="large"
            @change="funShi"
            >是</el-radio
          >
          <el-radio
            v-model="isNightPrice"
            label="0"
            size="large"
            @change="funShi"
            >否</el-radio
          >
        </div>

        <div class="time" v-if="showShi">
          <input
            class="open"
            type="text"
            placeholder="开启时间(时)"
            v-model="nightBegin"
          />
          <input
            class="over"
            type="text"
            placeholder="结束时间(时)"
            v-model="nightEnd"
          />
          <input
            class="night"
            type="text"
            placeholder="夜间价格"
            v-model="nightPrice"
          />
        </div>

        <div class="name2" v-if="rescueType == 1 || rescueType == 2 || rescueType ==  19" >
          <div class="xing1">*</div>
          <label class="label3" for="">默认路桥费</label>
          <el-radio v-model="bridgeStyle" label="0" size="large">车主付</el-radio>
          <el-radio v-model="bridgeStyle" label="1" size="large"
            >拖车公司承担</el-radio
          >
        </div>
        

        <div class="btn">
          <button class="set" @click="gosave">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import * as api from "../api/join.js";

const radio1 = ref("1");
const radio2 = ref("1");
const radio3 = ref("1");
const radio4 = ref("1");

const value = ref("1");

const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});

export default {
  // props:["israte"],
  props: {
    israte: {
      cid: String,
      id: String,
    },
    res: {
      type: Object,
    },
    resHeZuo: {
      type: Object,
      default: {},
    },
    cid: {
      type: String,
      default: "",
    },
    priceList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      jizhun: "基准价",
      juli: "基准公里",
      jia: "超基准价",
      form,
      radio1,
      radio2,
      radio3,
      radio4,
      value,
      priceAdd: [],
      rates: this.israte,
      showYiKou: false, // 一口价控制的显隐
      showShi: false, // 是控制的显隐
      listArr: [], // ul数组
      saveObj: {},
      fourId: "",
      type: "",
      fourList: [],
      rescueType: "1",
      chargeType: "1",
      isGua: "0",
      isNightPrice: "0",
      bridgeStyle: "0",
      Pcid: "",
      detailP: this.resHeZuo,
      pricenowid: "",
      nightBegin: "",
      nightEnd: "",
      nightPrice: "",
      iscarprice: 0,
      car_type:'',
      maxPrice:'',
      cartypelist:[
        {'id':1,'name':'2吨平板拖车'},
        {'id':2,'name':'3吨平板拖车'},
        {'id':3,'name':'5吨平板拖车'},
        {'id':4,'name':'8吨平板拖车'},
        {'id':9,'name':'落地平板'},
        {'id':13,'name':'16吨大拖车'},
        {'id':14,'name':'38吨大拖车'},
      ]
    };
  },
  methods: {
    close() {
      this.rates = false;
      this.chargeType = '1';
      this.showYiKou = false, // 一口价控制的显隐
      a.value = '';
      b.value = '';
      c.value = '';
      d.value = '';
    },
    funGong(label) {
      console.log("点击了：", label);
      this.showYiKou = label == "2" ? true : false;
      this.type = label;

      this.shaiXuan();
    },
    funShi(label) {
      console.log("点击了：", label);
      this.showShi = label == "1" ? true : false;
    },
    selectid(val) {
      this.pricenowid = val;
    },

    async add() {
      let obj = {};
      obj.x = this.chargeType == 1 ? "一口价" : "公里价";
      obj.a = a.value ? a.value : "";
      if (this.chargeType == 2) {
        obj.b = b.value ? b.value : 0;
        obj.c = c.value ? c.value : 0;
        obj.d = d.value ? d.value : 0;
      } else {
        obj.b = "";
        obj.c = "";
        obj.d = "";
      }

      if (obj.a == "") {
        // alert('请输入基准价格');
        this.$message.warning("请输入基准价格");
        return false;
      }

      if((this.rescueType == 1 || this.rescueType == 2 || this.rescueType == 19) && this.iscarprice == 1 && this.chargeType == 2)
      {
         if(this.car_type == '')
         {
           this.$message.warning('请选择对应车型');
           return false;

         }
         let res = await api.priceAddNew(
            this.Pcid,
            this.rescueType,
            this.chargeType,
            obj.a,
            obj.b,
            obj.c,
            obj.d,
            this.isGua,
            this.car_type,
          );
          if (res.code == 1) {
            this.shuaxin();
          } else {
            // alert(res.message);
            this.$message.warning(res.message);
            this.shuaxin();
          }
      }else{
          // console.log(this.maxPrice);
          // return false;
          let res = await api.priceAdd(
            this.Pcid,
            this.rescueType,
            this.chargeType,
            obj.a,
            obj.b,
            obj.c,
            obj.d,
            this.maxPrice
          );
          if (res.code == 1) {
            this.shuaxin();
          } else {
            // alert(res.message);
            this.$message.warning(res.message);
            this.shuaxin();
          }
      }

    },
    async jian() {
      if (this.pricenowid == "")
        // alert('无价格条目');
        this.$message.warning("无价格条目");
      else {
        let res = await api.priceDel(
          this.pricenowid,
          this.Pcid,
          this.rescueType
        );
        if (res.code == 1) {
          this.shuaxin();
        } else {
          // alert(res.message);
          this.$message.warning(res.message);
          this.shuaxin();
        }
      }
    },
    shaiXuan() {},
    async shuaxin() {
      let res = await api.priceList(this.Pcid, this.rescueType);
      this.detailP = res.data;
      this.iscarprice = parseInt(res.data.is_carprice);
      console.log("详情", this.detailP.priceDetail);

      if (this.detailP.priceDetail.isGua != "") {
        this.isGua = this.detailP.priceDetail.isGua;
      }

      if (this.detailP.priceDetail.isNightPrice != "") {
        this.isNightPrice = this.detailP.priceDetail.isNightPrice;
      } else {
        this.isNightPrice = "0";
      }

      if (this.detailP.priceDetail.nightBegin != "") {
        this.nightBegin = this.detailP.priceDetail.nightBegin;
      } else {
        this.nightBegin = "";
      }

      if (this.detailP.priceDetail.nightEnd != "") {
        this.nightEnd = this.detailP.priceDetail.nightEnd;
      } else {
        this.nightEnd = "";
      }

      if (this.detailP.priceDetail.nightPrice != "") {
        this.nightPrice = this.detailP.priceDetail.nightPrice;
      } else {
        this.nightPrice = "";
      }

      if (this.isNightPrice == 1) {
        this.showShi = true;
      } else {
        this.showShi = false;
      }

      if(this.detailP.priceDetail.bridgestyle != ''){
        this.bridgeStyle = this.detailP.priceDetail.bridgestyle;
      }else{
        this.bridgeStyle = "0";
      }

      if (this.detailP.priceDetail.priceList.length > 0) {
        this.listArr = [];
        for (var i = 0; i < this.detailP.priceDetail.priceList.length; i++) {
          if (this.detailP.priceDetail.priceList[i].chargeType == 1) {
            let obj = {};
            obj.id = this.detailP.priceDetail.priceList[i].id;
            obj.x =
              this.rescueType == 12
                ? "一口价"
                : "一口价" && this.rescueType == 13
                ? "一口价"
                : "一口价";
            obj.a =
              this.rescueType == 12
                ? this.detailP.priceDetail.priceList[i].guidePrice + "元"
                : this.detailP.priceDetail.priceList[i].guidePrice + "元" &&
                  this.rescueType == 13
                ? this.detailP.priceDetail.priceList[i].guidePrice + "元"
                : this.detailP.priceDetail.priceList[i].guidePrice + "元";
            obj.b = "";
            obj.c = "";
            obj.d = "";
            obj.e = "";
            this.listArr.push(obj);
          } else {
            let obj = {};
            obj.id = this.detailP.priceDetail.priceList[i].id;
            obj.x = (this.rescueType == 1 || this.rescueType == 2 || this.rescueType== 3) && this.iscarprice == 1 ?this.detailP.priceDetail.priceList[i].car_type:
              (this.rescueType == 12
                ? "层数价"
                : "公里价" && this.rescueType == 13
                ? "个数价"
                : "公里价");
            obj.a =
              this.rescueType == 12
                ? this.detailP.priceDetail.priceList[i].guidePrice + "元/"
                : this.detailP.priceDetail.priceList[i].guidePrice + "元/" &&
                  this.rescueType == 13
                ? this.detailP.priceDetail.priceList[i].guidePrice + "元/"
                : this.detailP.priceDetail.priceList[i].guidePrice + "元/";
            obj.b =
              this.rescueType == 12
                ? this.detailP.priceDetail.priceList[i].guideKm + "层"
                : this.detailP.priceDetail.priceList[i].guideKm + "公里" &&
                  this.rescueType == 13
                ? this.detailP.priceDetail.priceList[i].guideKm + "个"
                : this.detailP.priceDetail.priceList[i].guideKm + "公里";
            obj.c = 
              this.rescueType == 12
                ?'超' + this.detailP.priceDetail.priceList[i].kmPrice + "元/层"
                :'超' + this.detailP.priceDetail.priceList[i].kmPrice + "元/公里" &&
                  this.rescueType == 13
                ?'超' + this.detailP.priceDetail.priceList[i].kmPrice + "元/个"
                :'超' +this.detailP.priceDetail.priceList[i].kmPrice + "元/公里";
            obj.d =
              this.rescueType == 12
                ? this.detailP.priceDetail.priceList[i].guaKm != ""
                  ? '免' + this.detailP.priceDetail.priceList[i].guaKm + "层"
                  : ""
                : (this.detailP.priceDetail.priceList[i].guaKm != ""
                    ?'免' +  this.detailP.priceDetail.priceList[i].guaKm + "公里"
                    : "") && this.rescueType == 13
                ? this.detailP.priceDetail.priceList[i].guaKm != ""
                  ?'免' +  this.detailP.priceDetail.priceList[i].guaKm + "个"
                  : ""
                : this.detailP.priceDetail.priceList[i].guaKm != ""
                ? '免' + this.detailP.priceDetail.priceList[i].guaKm + "公里"
                : "";
             obj.e =
              this.detailP.priceDetail.priceList[i].max > 0?'最高'+this.detailP.priceDetail.priceList[i].max+'元':"";
            this.listArr.push(obj);
          }
          this.pricenowid = this.detailP.priceDetail.priceList[i].id;
        }
      } else {
        this.listArr = [];
        this.pricenowid = "";
      }
    },
    async delprice(id) {
      let res = await api.priceDel(id, this.Pcid, this.rescueType);
      if (res.code == 1) {
        this.shuaxin();
      } else {
        this.$message.warning(res.message);
        this.shuaxin();
      }
    },
    async gosave() {
      if (
        this.isNightPrice == 1 &&
        (this.nightBegin == "" || this.nightEnd == "" || this.nightPrice == "")
      ) {
        // alert('请填写夜间加价具体内容');
        this.$message.warning("请填写夜间加价具体内容");
      }

      let res = await api.priceSave(
        this.Pcid,
        this.rescueType,
        this.isGua,
        this.isNightPrice,
        this.nightBegin,
        this.nightEnd,
        this.nightPrice,
        this.bridgeStyle,
      );
      if (res.code == 1) {
        this.shuaxin();
        this.$message.success("保存成功");
      } else {
        // alert(res.message);
        this.$message.warning(res.message);
        this.shuaxin();
      }
    },
  },

  watch: {
    israte(newval) {
      this.rates = newval;
    },
    cid(newval) {
      this.Pcid = newval;
      this.shuaxin();
    },
    rescueType(newval) {
      this.rescueType = newval;
      this.shuaxin();
      if (this.rescueType === "12") {
        this.jizhun = "基准价";
        this.juli = "基准";
        this.jia = "超出";
      } else if (this.rescueType === "13") {
        this.jizhun = "基准价";
        this.juli = "基准";
        this.jia = "超出";
      } else {
        this.jizhun = "基准价";
        this.juli = "基准";
        this.jia = "超基准";
      }
    },
    nightBegin() {
      this.nightBegin = this.nightBegin.replace(/[^\d]/g, "");
    },
    nightEnd() {
      this.nightEnd = this.nightEnd.replace(/[^\d]/g, "");
    },
    nightPrice() {
      this.nightPrice = this.nightPrice.replace(/[^0-9.]/g, "");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: 22;
  //   background-color: pink;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: 70px;
  margin-left: 0px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 170px;
  height: 26px;
  font-size: 20px;
  padding-top: 21px;
  padding-left: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.name {
  margin-top: 24px;
  margin-left: 30px;
}
.name1 {
  margin-top: 22px;
  margin-left: 38px;
}
.name2 {
  margin-top: 22px;
  margin-left: 34px;
}
.label1,
.label2,
.label3 {
  width: 100px;
  display: inline-block;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}

input {
  width: 260px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
  border: 1px solid #e0e0e0;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.xing,
.xing1,
.xing2 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing2 {
  color: #fff;
}
.xing {
  margin-top: 14px;
}
.xing1 {
  margin-top: 20px;
}
.el-select {
  width: 260px;
}

.priceelect {
  width: 460px;
  height: 32px;
  margin-left: 140px;
  margin-top: 12px;
  position: relative;
  //background-color: skyblue;
}

.priceelect1,
.priceelect2,
.priceelect3,
.priceelect4 {
  float: left;
  margin-right: 1px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  display: flex;
}
.priceelect1,
.priceelect2 {
  // width: 80px;
  height: 32px;
}
.priceelect3 {
  // width: 100px;
  height: 32px;
}
.priceelect4 {
  // width: 110px;
  height: 32px;
}
.priceelect-span1 {
  width: 55px;
  height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  color: #2a3346;
  opacity: 1;
  border: none;
  padding: 0px;
}
.priceelect-num {
  padding-left: 9px;
}
.priceelect-span2,
.priceelect-span3,
.priceelect-span4,
.priceelect-span5,
.priceelect-span6,
.priceelect-span7 {
  display: flex;
  align-items: center;
  justify-content: center;
  // display: inline-block;
  //height: 32px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  box-sizing: border-box;
}
.priceelect-span2 {
  width: 40px;
  //margin-left: 39px;
}
.priceelect-span3 {
  width: 40px;
  //margin-left: 39px;
}
.priceelect-span4 {
  width: 60px;
  //margin-left: 39px;
}
.priceelect-span5 {
  width: 40px;
  //margin-left: 39px;
}
.priceelect-span6 {
  width: 95px;
  //margin-left: 39px;
}
.priceelect-span7 {
  // width: 150px;
  //padding-right: 5px;
  //margin-left: 39px;
}
.add {
  float: left;
  width: 30px;
  height: 32px;
  position: absolute;
  top: 0px;
  right: 17px;
}

// 点击加号添加
.item-box {
  width: 410px;
  height: 140px;
  // background-color: pink;
  margin-left: 141px;
  border: 1px solid #dadfe6;
  overflow-y: scroll;
}

.item-box::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

ul {
  list-style: none;
  padding-left: 5px;
}

.minus {
  float: left;
  width: 30px;
  height: 32px;
  position: absolute;
  top: 436px;
  right: 17px;
}

.time {
  height: 40px;
  margin-left: 137px;
  margin-top: 22px;
  // background-color: orange;
}
.open,
.over,
.night {
  width: 120px;
  height: 40px;
  margin-right: 10px;
  text-align: center;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
