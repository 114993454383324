<template>
  <div>
    <div class="box" v-show="coms">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>人员管理</h1>
          <img class="shua" src="../assets/img/ding/shua.png" alt="" />
          <i class="xin" @click="sx">刷新</i>
        </div>

        <div class="nav-box">
          <div class="mt-4">
            <el-input
              v-model="keyword"
              placeholder="请输入搜索内容"
              class="input-with-select"
            >
              <template #append>
                <el-button class="iconfont icon-fangdajing" @click="getlist()"></el-button>
              </template>
            </el-input>
          </div>
          <div @click="screen" class="screen">
            <img
              class="screen-img"
              src="../assets/img/slices5/list-icon-add.png"
              alt=""
            />
            <p class="screen-p">添加</p>
          </div>
        </div>
        <div class="tablebox">
          <table class="managetable" border="0" cellspacing="0">
          <thead class="hand">
            <th class="name">姓名/帐号</th>
            <th class="limits">权限</th>
            <th class="weixin">微信</th>
            <th class="marketing">奖励</th>
            <th class="time">添加时间</th>
            <th class="operate">操作</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in resData" :key="index">
              <td class="td-name">{{ item.name }}/<br />{{ item.username }}</td>
              <td class="td-limits">{{ item.role }}</td>
              <td class="td-weixin">{{ item.bind }}</td>
              <td class="td-marketing">{{ item.marketing }}</td>
              <td class="td-time">{{ item.create_time }}</td>
              <td class="td-xuan">
                <i @click="look(item.uid)" class="check">查看</i>
                <i class="tiao" v-if="item.canManage"></i>
                <i @click="direct(item.uid)" v-if="item.canManage" class="mark"
                  >奖励</i
                >
                <i class="tiao" v-if="item.canManage"></i>
                <i
                  class="know"
                  v-if="item.canManage && item.status == 1"
                  @click="disable(item.uid)"
                  >已启用</i
                >
                <i
                  class="know"
                  style="color: red"
                  v-if="item.canManage && item.status == 0"
                  @click="enable(item.uid)"
                  >已禁用</i
                >
                <!--<i class="tiao"  v-if="item.canManage"></i>-->
                <!--<i @click="log(uid)" class="daily"  v-if="item.canManage">日志</i>-->
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        
      </div>
    </div>

    <limitsadd
      :islimiadd="limiadd"
      :src_cid="src_cid"
      @limitaddssx="sx2"
    ></limitsadd>
    <limits :islimi="limi" :limitsuid="uid" @limitssx="sx1"></limits>
    <journal :isjour="jour" :journaluid="uid"></journal>
    <marketing :ismarke="marke" :marketinguid="uid"></marketing>
    <enable :isable="able" :enableuid="uid" @enablesx="sx"></enable>
  </div>
</template>

<script>
import { ref } from "vue";
import limits from './limits.vue';
import limitsadd from './limitsadd.vue';
import Journal from './journal.vue';
import Marketing from './marketing.vue';
import * as api from "../api/join.js";
import * as api1 from "../api/user.js";
import enable from "./enable.vue";
import Detail from "./detail.vue";
const input3 = ref("");

export default {
  props:["iscom","srccid"],

  components: { limits, Journal, Marketing, enable, Detail, limitsadd},
  data() {
    return {
      input3,
      coms:this.iscom,
      limi:0,
      jour:0,
      marke:0,
      resData:{},
      uid:'',
      able:0,
      src_cid:this.srccid,
      limiadd:0,
      keyword:''
    };
  },


  methods: {
      close() {
        this.coms = false;
        // this.com = !this.com;
      },
      async getlist(){
          let res = await api.manageList(this.src_cid,this.keyword);
          this.resData = res.data;
      },
      sx(){
        this.getlist();
      },
      sx1(){
        this.getlist();
      },
      sx2(){
        this.getlist();
      },
      screen(){
        this.limiadd++;
        if(this.limi == 3){
           this.limi = 1
        }
      },
      look(uid) {
        this.uid = uid;
        this.limi++;
        if(this.limi == 3){
          this.limi = 1
        }
      },
      log(uid) {
        this.uid = uid;
        this.jour++;
        if(this.jour == 3){
          this.jour = 1
        }
      },
      direct(uid) {
        this.uid = uid;
        this.marke++;
        if(this.marke == 3){
          this.marke = 1
        }
      },
      async disable(uid){
          let userdisable = await api1.userDodisable(uid);
          if(userdisable.code == 1)
          {
            this.$message.success('已禁用')
            this.getlist();
          }else{
            this.$message.warning(userdisable.message);
          }
      },
      enable(uid){
          this.uid = uid;
          this.able++;
          if(this.able == 3 ){
            this.able = 1
          }
      }
  },
  watch:{
    iscom(newval){
      console.log('---',newval);
      this.coms = newval
    },
    async srccid(newval) {
      console.log("传过来的res合作商人员数据：", newval);
      this.src_cid = newval;
      this.getlist();
    },
  },


};
</script>

<style lang="less" scoped>
.box {
  // width: 760px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;

  z-index: 22;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: 70px;
  margin-left: -40px;
}
i {
  font-style: normal;
}
.xin {
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 44px;
  right: 40px;
  opacity: 1;
  cursor: pointer;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0px;
  right: 80px;
  cursor: pointer;
}
.box-main {
  width: 760px;
  height: 920px;
  //   background: orange;
  // margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  background-color: #fff;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 170px;
  height: 26px;
  font-size: 20px;
  padding-top: 21px;
  padding-left: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.nav-box {
  width: 720px;
  height: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
  //   background-color: orange;
}
.mt-4 {
  float: left;
  width: 300px;
  height: 40px;
  margin-top: 10px;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 0px;
  margin-right: 30px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.screen-img {
  float: left;
  width: 22px;
  height: 23px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
}
.screen-p {
  float: left;
  margin-top: 27px;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}

.name,
.limits,
.weixin,
.marketing,
.time,
.operate {
  font-size: 14px;
  height: 19px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  text-align: left;
}
.name {
  width: 100px;
  padding-left: 30px;
}

.td-name,
.td-limits,
.td-weixin,
.td-marketing,
.td-time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  box-sizing: border-box;
  opacity: 1;
  padding-right: 10px;
}
.td-name {
  // width: 150px;
  height: 19px;
  padding-left: 30px;
}
.td-limits {
  width: 150px;
  height: 19px;
}
.td-weixin {
  // width: 28px;
  height: 19px;
}
.td-marketing {
  // width: 28px;
  height: 19px;
}
.td-time {
  // width: 78px;
  height: 38px;
}
.td-xuan {
  // width: 245px;
  height: 19px;
  //   background-color: pink;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin: 0 6px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.check,
.mark,
.know,
.limit,
.daily {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
}
.check,
.mark,
.limit,
.daily {
  width: 28px;
  height: 20px;
  cursor: pointer;
}
.know {
  width: 42px;
  height: 20px;
  cursor: pointer;
}

.managetable {
  width: 100%;
}
.tablebox{
  height: 80%;
  overflow-y: scroll;
}
</style>
