<template>
  <div class="ruleBox" v-if="isgui">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="ruleMain">
      <h1>空驶规则</h1>
      <div class="xuan">
        <div class="xuanze">
          <label for="" class="xuanTit">规则&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="1" size="large" @change="funTime"
              >接单后多少元/公里,上限为基准的百分比</el-radio
            >
          </el-radio-group>
        </div>
        <div class="xuanze">
          <label for="" class="xuanTit">&#12288;&#12288;&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="2" size="large" @change="funTime"
              >全部免费</el-radio
            >
          </el-radio-group>
        </div>
        <div class="xuanze">
          <label for="" class="xuanTit">&#12288;&#12288;&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="3" size="large" @change="funTime"
              >多少公里内免费,超公里后多少元/公里,上限为基准的百分比</el-radio
            >
          </el-radio-group>
        </div>
        <div class="xuanze">
          <label for="" class="xuanTit">&#12288;&#12288;&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="4" size="large" @change="funTime"
              >取消有费和救援失败</el-radio
            >
          </el-radio-group>
        </div>
         <div class="xuanze">
          <label for="" class="xuanTit">&#12288;&#12288;&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="5" size="large" @change="funTime"
              >按服务项目设置</el-radio
            >
          </el-radio-group>
        </div>
        <div class="xuanze">
          <label for="" class="xuanTit">&#12288;&#12288;&#12288;&#12288;</label>
          <el-radio-group v-model="radio1">
            <el-radio label="6" size="large" @change="funTime"
              >按时间设置</el-radio
            >
          </el-radio-group>
        </div>


        <div class="yin">
          <div class="yinbox" v-show="over">
            <div class="juliTit">
              <label class="juliNav" for=""
                >&#12288;&#12288;&#12288;&#12288;</label
              >
              <div class="shuBox">
                <div class="shu">
                  <div class="wei">
                    <input
                      class="juliNum"
                      type="text"
                      v-model="yuan"
                      placeholder="请输入"
                    />
                    <span class="gongli">元/公里</span>
                  </div>

                  <div class="wei">
                    <input
                      class="juliNum"
                      type="text"
                      v-model="xian"
                      placeholder="总价百分比"
                    />
                    <span class="gongli">%上限</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="yinbox" v-show="over1">
            <div class="juliTit">
              <label class="juliNav" for=""
                >&#12288;&#12288;&#12288;&#12288;</label
              >
              <div class="shuBox">
                <div class="shu">
                  <input
                    class="juliNum"
                    type="text"
                    v-model="yuan"
                    placeholder="超出公里"
                  />
                  <span class="gongli">元/公里</span>
                  <input
                    class="juliNum"
                    type="text"
                    v-model="xian"
                    placeholder="总价百分比"
                  />
                  <span class="gongli">%上限</span>
                  <input
                    class="juliNum"
                    type="text"
                    v-model="chaomian"
                    placeholder="免费"
                  />
                  <span class="gongli">公里</span>
                </div>
              </div>
            </div>
          </div>

          <div class="yinbox" v-show="over2">
            <div class="juliTit">
              <label class="juliNav" for=""
                >&#12288;&#12288;&#12288;&#12288;</label
              >
              <div class="shuBox">
                <el-form ref="form">
                  <el-form-item label="取消有费">
                    <el-radio v-model="shenHeVal" label="1" @change="rulechange"
                      >一口价</el-radio
                    >
                    <el-radio v-model="shenHeVal" label="2" @change="rulechange"
                      >百分比</el-radio
                    >
                  </el-form-item>
                </el-form>
                <div class="shu">
                  <div class="wei" v-if="ruleprice">
                    <input
                      class="ruleNum"
                      type="text"
                      v-model="caleyuan"
                      placeholder="请输入"
                    />
                    <span class="gongli">元</span>
                  </div>

                  <div class="wei" v-if="ruleprice2">
                    <input
                      class="ruleNum"
                      v-model="calexian"
                      placeholder="总价百分比"
                      type="number"
                      oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100)value=100;if(value<0)value=null"
                    />
                    <span class="gongli">%上限</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="juliTit">
              <label class="juliNav" for=""
                >&#12288;&#12288;&#12288;&#12288;</label
              >
              <div class="shuBox">
                <el-form ref="form">
                  <el-form-item label="救援失败">
                    <el-radio v-model="shenHeVal2" label="1" @change="jiuchange"
                      >一口价</el-radio
                    >
                    <el-radio v-model="shenHeVal2" label="2" @change="jiuchange"
                      >百分比</el-radio
                    >
                  </el-form-item>
                </el-form>
                <div class="shu">
                  <div class="wei" v-if="jiuprice">
                    <input
                      class="ruleNum"
                      type="text"
                      v-model="jiuyuan"
                      placeholder="请输入"
                    />
                    <span class="gongli">元</span>
                  </div>

                  <div class="wei" v-if="jiuprice2">
                    <input
                      class="ruleNum"
                      type="number"
                      v-model="jiuxian"
                      placeholder="总价百分比"
                      oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');if(value>100)value=100;if(value<0)value=null"
                    />
                    <span class="gongli">%上限</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="xuanze xuanju" v-if="this.over3">
          <label class="xuanTit" for="">服务项目</label>
          <el-select v-model="kongvalue" placeholder="请选择" style="width:350px;">
            <el-option
              v-for="item in kongguilist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="xuanze xuanju"  v-if="this.over3">
          <label class="xuanTit" for="">空驶设置</label>
          <div class="shu">
            <div class="wei">
              <input
                class="juliNum2"
                type="text"
                v-model="kmyuan"
                placeholder="空驶价格"
              />
              <span class="gongli">元/公里</span>
            </div>
            <div class="wei">
            <el-select v-model="pt" placeholder="" style="width:70px;">
              <el-option
                v-for="item in ptlist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
              <input
                class="juliNum2"
                type="text"
                v-model="kmxian"
                placeholder="最高上限"
              />
            </div>           
            <el-button type="primary" @click="fuwuadd">设置</el-button>
          </div>
        </div>

        <div class="item-box"  v-if="this.over3">
          <ul>
            <li class="fuwuli" v-for="(item, index) in fuwuArr" :key="index">
              {{ item.rescuetypename }}：{{ item.a }}元/公里  <span v-if="item.b == 1">{{ item.c }}%</span><span v-if="item.b == 2">{{ item.c }}元</span>
              <el-button type="danger" size="mini" @click="delprice(index)"
                >删除</el-button
              >
            </li>
          </ul>
        </div>
      </div>


      <div class="yinbox" v-show="over4">
            <div class="juliTit">
              <label class="juliNav" for=""
                >&#12288;&#12288;&#12288;&#12288;</label
              >
              <div class="shuBox">
                <div class="shu">
                  <span class="gongli1" style="margin-left:35px;">超出</span>
                  <el-select v-model="timetype" placeholder="时间类型" style="width:100px;">
                        <el-option
                          v-for="item in ttlist"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        >
                        </el-option>
                  </el-select>
                  <input
                    class="juliNum"
                    style="width:60px;text-align:center;"
                    type="number"
                    v-model="minute"
                    placeholder="N"
                  />
                  <span class="gongli">分钟后</span>
                  <input
                    class="juliNum"
                    type="number"
                    style="text-align:center;"
                    v-model="percent1"
                    placeholder="标准价百分比"
                  />
                  <span class="gongli">%</span>
                </div>
              </div>
            </div>
          </div>


      <!-- 按钮 -->
      <div class="btn">
        <button class="set" @click="bao(this.guiId)">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/join.js";
export default {
  props: ["isgui", "konggui", "guiId","kongguilist"],
  data() {
    return {
      //   guis: this.isgui,
      radio1: "",
      over: false,
      over1: false,
      over2: false,
      over3: false,
      over4: false,
      label: "",
      yuan: "",
      xian: "",
      chaomian: "",
      shenHeVal: "",
      shenHeVal2: "",

      rulelabel: "",
      jiulabel: "",
      caleyuan: "",
      calexian: "",
      jiuyuan: "",
      jiuxian: "",
      ruleprice: false,
      ruleprice2: false,
      jiuprice: false,
      jiuprice2: false,
      ttlist:[
        {
          id:1,
          name:'创建时间',
        },
        {
          id:2,
          name:'接单时间'
        },
        {
          id:3,
          name:'出发时间'
        },
      ],

      options: [
        {
          value: "1",
          label: "拖车",
        },
        {
          value: "2",
          label: "搭电",
        },
        {
          value: "3",
          label: "换胎",
        },
        {
          value: "4",
          label: "送水",
        },
        {
          value: "5",
          label: "充气",
        },
      ],
      ptlist:[
        {
          value:'1',
          label:'百分比'
        },
        {
          value:'2',
          label:'金额'
        },
      ],
      fuwuArr: [
      ],
      kongvalue: "",
      kmxian: "",
      pt:"1",
      kmyuan: "",
      timetype:"",
      minute:'',
      percent1:'',
    };
  },

  methods: {
    fuwuadd() {  
      let tit = this.kongvalue;
      let kmyuan = this.kmyuan;
      let pt = this.pt;
      let kmxian = this.kmxian;
      if(tit == '' || kmyuan =='' || kmxian == '')
      {
        this.$message.warning('请填充完空驶内容');
        return false;
      }
      let newArr = this.fuwuArr.filter(function (ele) {
        return ele.rescuetype == tit;
      });

      let newArr1 = this.kongguilist.filter(function (ele) {
        return ele.id == tit;
      });

     if(newArr.length == 0)
     {
        let data = {
          rescuetype:tit,
          rescuetypename:newArr1[0].name,
          a:kmyuan,
          b:pt,
          c:kmxian
        };
        this.fuwuArr.push(data);
     }else{
       this.$message.warning('已有此项目的空驶方案');
     }

     console.log(this.fuwuArr)

    },
    delprice(index) {
      this.fuwuArr.splice(index, 1);
    },
    close() {
      this.$emit("closegui");
      this.over2 = false;
      this.over3 = false;
      this.ruleprice = false;
      this.ruleprice2 = false;
      this.jiuprice = false;
      this.jiuprice2 = false;
      this.fuwuArr = [];
      this.kongvalue = '';
      this.kmxian = '';
      this.kmyuan = '';
    },
    funTime(label) {
      console.log("信息---------", this.konggui, this.guiId);
      if (label == 1) {
        this.over = true;
        this.label = label;
        if (this.konggui.type == this.label) {
          this.yuan = this.konggui.x;
          this.xian = this.konggui.y;
          this.chaomian = this.konggui.z;
        } else {
          this.yuan = "";
          this.xian = "";
          this.chaomian = "";
        }
      } else {
        this.over = false;
      }
      if (label == 2) {
        this.label = label;
      }
      if (label == 3) {
        this.over1 = true;
        this.label = label;
        if (this.konggui.type == this.label) {
          this.yuan = this.konggui.x;
          this.xian = this.konggui.y;
          this.chaomian = this.konggui.z;
        } else {
          this.yuan = "";
          this.xian = "";
          this.chaomian = "";
        }
      } else {
        this.over1 = false;
      }
      if (label == 4) {
        this.over2 = true;
        this.label = label;
      } else {
        this.over2 = false;
      }

      if (label == 5) {
        this.over3 = true;
        this.label = label;
      } else {
        this.over3 = false;
      }

      if(label == 6)
      {
        this.over4 = true;
        this.label = label;
      }else{
        this.over4 = false;
      }
    },
    rulechange(a) {
      this.rulelabel = a;
      if (a == 1) {
        this.ruleprice = true;
        this.ruleprice2 = false;
      } else if (a == 2) {
        this.ruleprice2 = true;
        this.ruleprice = false;
      }
    },
    jiuchange(c) {
      this.jiulabel = c;
      if (c == 1) {
        this.jiuprice = true;
        this.jiuprice2 = false;
      } else if (c == 2) {
        this.jiuprice2 = true;
        this.jiuprice = false;
      }
    },
    async bao() {
      let cid = this.guiId;
      let type = parseInt(this.radio1);

      let a = this.rulelabel;
      let b =
        this.rulelabel == 1
          ? this.caleyuan
          : "" || this.rulelabel == 2
          ? this.calexian
          : "";
      let c = this.jiulabel;
      let d =
        this.jiulabel == 1
          ? this.jiuyuan
          : "" || this.jiulabel == 2
          ? this.jiuxian
          : "";
      let x = this.yuan;
      let y = this.xian;
      let z = this.chaomian;

      if (type < 4) {
        let res = await api.setRule(cid, type, x, y, z);
        if (res.code == 1) {
          this.$message.success("保存成功");
          this.close();
        } else {
          this.$message.warning(res.message);
        }
      } else if(type == 4){
        console.log(a)
        console.log(b)
        console.log(c)
        console.log(d)
        if (this.shenHeVal == 1) {
          if (this.caleyuan == "") {
            this.$message("请输入取消有费价格");
            return false;
          }
        } else if (this.shenHeVal == 2) {
          if (this.calexian == "") {
            this.$message("请输入取消有费百分比");
            return false;
          }
        }

        if (this.shenHeVal2 == 1) {
          if (this.jiuyuan == "") {
            this.$message("请输入救援失败价格");
            return false;
          }
        } else if (this.shenHeVal2 == 2) {
          if (this.jiuxian == "") {
            this.$message("请输入救援失败百分比");
            return false;
          }
        }
        let res2 = await api.setRule2(cid, type, a, b, c, d);
        if (res2.code == 1) {
          this.$message.success("保存成功");
          this.close();
        } else {
          this.$message.warning(res2.message);
        }
      }else if(type == 5)
      {
        if(this.fuwuArr.length == 0)
        {
            this.$message("请设置空驶具体内容");
            return false;
        }else{
          let list = this.fuwuArr;
          let res2 = await api.setRule3(cid, type,list);
          if (res2.code == 1) {
            this.$message.success("保存成功");
            this.close();
          } else {
            this.$message.warning(res2.message);
          }
        }
      }else if(type == 6)
      {
          if(this.timetype == '')
          {
            this.$message("请选择具体时间类型");
            return false;
          }

          if(this.minute == '')
          {
            this.$message("请确认具体时间");
            return false;
          }

          if(this.percent == '')
          {
            this.$message("请确认收费百分比");
            return false;
          }

          if(this.percent > 100)
          {
            this.$message("收费百分比不能超过100");
            return false;      
          }

          let  x = this.timetype;
          let  y = this.minute;
          let  z = this.percent1;

          let res = await api.setRule(cid, type, x, y, z);
          if (res.code == 1) {
            this.$message.success("保存成功");
            this.close();
          } else {
            this.$message.warning(res.message);
          }
      }
    },
  },
  watch: {
    konggui(newval1) {
      console.log("eeeee", newval1);
      if(newval1.length > 0){
          if(newval1[0].type <= 4)
          {
            let newval = newval1[0];
              if (newval.type == 1) {
                this.over = true;
                this.over1 = false;
                this.over2 = false;
                this.over3 = false;

              } else if (newval.type == 3) {
                this.over1 = true;
                this.over = false;
                this.over2 = false;
                this.over3 = false;
              } else if (newval.type != 3 && newval.type != 1 && newval.type != 4) {
                this.over = false;
                this.over1 = false;
                this.over2 = false;
                this.over3 = false;
              } else if (newval.type == 4) {
                this.over2 = true;
                this.over = false;
                this.over1 = false;
                this.over3 = false;
              }

              this.over4 = false;

              this.radio1 = newval.type;
              this.yuan = newval.x;
              this.xian = newval.y;
              this.chaomian = newval.z;

              this.shenHeVal = newval.cancel_type;
              this.shenHeVal2 = newval.fail_type;
              if (newval.cancel_type == 1) {
                this.ruleprice = true;
                this.caleyuan = newval.cancel_txt;
                this.ruleprice2 = false;
              } else if (newval.cancel_type == 2) {
                this.ruleprice2 = true;
                this.calexian = newval.cancel_txt;
                this.ruleprice = false;
              }
              if (newval.fail_type == 1) {
                this.jiuprice = true;
                this.jiuyuan = newval.fail_txt;
                this.jiuprice2 = false;
              } else if (newval.fail_type == 2) {
                this.jiuprice2 = true;
                this.jiuxian = newval.fail_txt;
                this.jiuprice = false;
              }
          }else if(newval1[0].type == 5){
                this.over  = false;
                this.over1 = false;
                this.over2 = false;
                this.over3 = true;
                this.over4 = false;
                this.radio1 = '5';

                newval1.forEach(element => {
                  
                    let tit = element.rescue_type;
                    let kmyuan = element.x;
                    let pt = element.z;
                    let kmxian = element.y;

                    let newArr1 = this.kongguilist.filter(function (ele) {
                      return ele.id == tit;
                    });

                    let data = {
                        rescuetype:tit,
                        rescuetypename:newArr1[0].name,
                        a:kmyuan,
                        b:pt,
                        c:kmxian
                      };
                      this.fuwuArr.push(data);
                });
            }else if(newval1[0].type == 6){
                this.over  = false;
                this.over1 = false;
                this.over2 = false;
                this.over3 = false;
                this.over4 = true;
                this.radio1 = '6';

                let newval = newval1[0];

                this.timetype = parseInt(newval.x);
                this.minute  = newval.y;
                this.percent1 = newval.z;
            }
      }else{
          this.over  = false;
          this.over1 = false;
          this.over2 = false;
          this.over3 = false;
          this.over4 = false;
          this.radio1 = '';
      }
    },
    percent1(newval)
    {
      if(newval > 100)
        this.percent = 100;
      else if(newval < 0)
        this.percent = 0;
    }
  },
};
</script>

<style lang="less" scoped>
.ruleBox {
  width: 760px;
  height: 920px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
h1 {
  padding-top: 20px;
  padding-left: 30px;
  //   height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  box-sizing: border-box;
  opacity: 1;
}
.xuan {
  margin-left: 30px;
  display: flex;
  // height: 250px;
  //   align-items: center;
  flex-direction: column;
}
.xuanze {
  display: flex;
  align-items: center;
}
.xuanju {
  margin-bottom: 20px;
}
.xuanTit {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 12px;
}
.yin {
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.juliTit,
.shuBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.wei {
  display: flex;
  align-items: center;
}
.juliNav {
  margin-right: 12px;
}
.juliNum {
  width: 115px;
  height: 32px;
  border: 1px solid #dcdfe6;
  padding-left: 12px;
  box-sizing: border-box;
  outline: none;
}
.ruleNum {
  width: 110px;
  height: 32px;
  border: 1px solid #dcdfe6;
  padding-left: 12px;
  box-sizing: border-box;
  outline: none;
}
.gongli {
  display: inline-block;
  height: 32px;
  // line-height: 32px;
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-left: 0;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f7f9fb;
}
.gongli1 {
  display: inline-block;
  height: 32px;
  // line-height: 32px;
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-right: 0;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f7f9fb;
}
.shu,
.gongli {
  margin-right: 12px;
  display: flex;
}
.btn {
  margin-top: 46px;
  margin-left: 30px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}

.el-radio-group {
  display: flex;
  flex-wrap: nowrap;
}
.shuBox /deep/ .el-radio {
  margin-right: 10px;
}
.shuBox /deep/ .el-form-item {
  margin: 0;
}
.shuBox /deep/ .el-form-item--default .el-form-item__label {
  font-weight: bold;
}

.item-box {
  width: 410px;
  height: 140px;
  margin-left: 70px;
  border: 1px solid #dadfe6;
  overflow-y: scroll;
}

.item-box::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.fuwuli {
  padding-left: 10px;
  font-size: 16px;
  margin: 5px 0;
}
.fuwuli /deep/ .el-button {
  width: 50px;
  height: 25px;
  line-height: 25px;
  margin-left: 10px;
}
.juliNum2 {
width: 70px;
height: 32px; border: 1px solid #dcdfe6; padding-left: 12px;
box-sizing: border-box;
outline: none;
}
.item-box {
 width: 350px;
 height: 140px;
 margin-left: 70px;
 border: 1px solid #dadfe6;
 overflow-y: scroll;
}
</style>