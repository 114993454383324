<template>
  <div>
    <div class="box" v-show="limis">
      <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>合作单位人员</h1>
        </div>
        <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">帐号</label>
          <input type="text" v-model="detail.username" disabled/>
        </div>
        <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">姓名</label>
          <input type="text" v-model="name"/>
        </div>
        <div class="name">
          <div class="xing">*</div>
          <label class="label2" for="">联系电话</label>
          <input type="text"  v-model="tel" />
        </div>

        <div class="xing1">*</div>
        <label class="label3" for="">权限</label>
        <div class="choice">
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="checkedLists"
            @change="handleCheckedListChange"
          >
            <el-checkbox
              v-for="item in rolelist"
              :label="parseInt(item.id)"
              :key="parseInt(item.id)"
              class="drag-item"
              border
            >
              <span>{{ item.name }}</span>
            </el-checkbox>
          </el-checkbox-group>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
        </div>

        <div class="btn">
          <button class="set" :plain="true" @click="goupdate(uid)">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api/user';
export default {
  props:["islimi","limitsuid"],
  data() {
    return {
      limis:this.islimi,
      rolelist: [],
      checkedLists: [],
      checkAll: false,
      isIndeterminate: false,
      detail:{},
      uid:'',
      name:'',
      tel:'',
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedLists = val
        ? this.rolelist.map((item) => {
            return parseInt(item.id);
          })
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedListChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.rolelist.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.rolelist.length;
    },
    close() {
      this.limis = false;
    },
     async goupdate(uid){
         
        var newrole ='';
        this.checkedLists.forEach((item,index) => {
          if(index == 0) {
            newrole = item;
          }else{
            newrole = newrole + ',' + item
          }
        })

        let res = await api.userDoEdit(uid,'','',this.name,this.tel,newrole);

        if(res.code == 1)
        {
          this.$message.success('成功');
          this.$emit('limitssx',new Date().getTime());
          this.limis = false;
        }else{

          this.$message.warning(res.message)
        }
    },
  },
  watch:{
    islimi(newval){
      //console.log('---',newval);
      this.limis = newval
    },
    async limitsuid(newval){
        console.log(newval);
        this.uid = newval;
        let res_userdetail = await api.userDetail(newval);     
        this.detail = res_userdetail.data;
        this.name = res_userdetail.data.name;
        this.tel = res_userdetail.data.tel;
        this.rolelist = res_userdetail.data.rolelist;
        this.checkedLists = res_userdetail.data.nowrole;     
    }
  }
};
</script>

<style lang="less" scoped>
.el-checkbox{
    width: 160px;
    border: 0;
    padding-left: 0;
    margin-bottom: 10px;
}

.drag-item {
  margin-bottom: 10px;
}
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  //   background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 260px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.name {
  margin-top: 24px;
  margin-left: 33px;
}
.label1,
.label2,
.label3 {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.label1 {
  margin-right: 48px;
}
.label2 {
  margin-right: 20px;
}
.label3 {
  float: left;
  margin-top: 30px;
  margin-right: 15px;
}
input {
  width: 260px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 12px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  outline: none;
}
input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.xing,
.xing1 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 15px;
}
.xing1 {
  margin-top: 30px;
}
.choice {
  float: left;
  width: 400px;
  margin-bottom: 44px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
