<template>
  <div>
    <div class="box" v-show="limisadd">
      <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>合作单位人员</h1>
        </div>
        <!-- 帐号 -->
        <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">帐号</label>
          <input type="text" v-model="username" placeholder="请输入帐号"/>
        </div>
        <div class="name" v-if="isSms">
          <div class="xing">*</div>
          <label class="label2" for="">短信验证</label>
          <input type="text"  v-model="smsCode" style="width: 120px;" placeholder="请输入验证码"/>
          <el-button :plain="true" @click="send(username)" id="gain2">获取验证码</el-button>
        </div>
          <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">密码</label>
          <input
            class="num-input"
            type="text"
            id="password"
            placeholder="请输入密码"
            v-model="password"
          />
        </div>
        <div class="name">
          <div class="xing">*</div>
          <label class="label2" for="">确认密码</label>
          <input
            class="verify-input"
            type="text"
            id="password_repeat"
            placeholder="请确认密码"
            v-model="password_repeat"
          />
        </div>
        <!-- 姓名 -->
        <div class="name">
          <div class="xing">*</div>
          <label class="label1" for="">姓名</label>
          <input type="text" v-model="name" placeholder="请输入姓名"/>
        </div>
        <!-- 联系电话 -->
        <div class="name">
          <div class="xing">*</div>
          <label class="label2" for="">联系电话</label>
          <input type="text"  v-model="tel" placeholder="请输入电话号码"/>
        </div>
        <!-- 权限 -->
        <div class="xing1">*</div>
        <label class="label3" for="">权限</label>
        <div class="choice">
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="checkedLists"
            @change="handleCheckedListChange"
          >
            <el-checkbox
              v-for="item in rolelist"
              :label="parseInt(item.id)"
              :key="parseInt(item.id)"
              class="drag-item"
              border
            >
              <span>{{ item.name }}</span>
            </el-checkbox>
          </el-checkbox-group>
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
        </div>

        <div class="btn">
          <button class="set" :plain="true" @click="adddate(uid)">添加</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api/user';
let num2 = 60;
let timer2;
export default {
  props:["islimiadd","src_cid"],
  data() {
    return {
      limisadd:this.islimiadd,
      rolelist: [],
      checkedLists: [],
      checkAll: false,
      isIndeterminate: false,
      detail:{},
      srccid:'',
      username:'',
      uid:'',
      name:'',
      password:'',
      password_repeat:'',
      tel:'',
      isSms:false,
      smsCode:'',
      num2,
      timer2,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedLists = val
        ? this.rolelist.map((item) => {
            return parseInt(item.id);
          })
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedListChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.rolelist.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.rolelist.length;
    },
    close() {
      this.limisadd = false;
      clearInterval(this.timer2)
      this.timer2 = null;
      this.num2 = 60;
      if(this.isSms == true)
      {
        gain2.innerHTML = '获取验证码';
      }
      
      this.smsCode = '';
      this.isSms = false;
      this.username = '';
      this.checkedLists = [];
      this.tel = '';
      this.name = '';
      this.password = '';
      this.password_repeat = '';
    },
    async adddate(uid){
        
      var newrole ='';
      this.checkedLists.forEach((item,index) => {
        if(index == 0) {
          newrole = item;
        }else{
          newrole = newrole + ',' + item
        }
      })

      let res = await api.userAddmore(this.srccid,this.username,this.name,this.tel,newrole,this.smsCode,this.password,this.password_repeat);

      if(res.code == 1)
      {
        this.$message.success('成功');
        this.$emit('limitaddssx',new Date().getTime());
        this.close();
      } else if(res.code == 0){
          this.$message.warning(res.message);
        } else if(res.code == 2)
        {
          this.isSms = true;    
          this.$message.warning(res.message);
        }
  },
   async send(mobile) {
          let res = await api.usersendMessage(mobile);

          if(res.code == 1){
              gain2.disabled = true;
              timer2=setInterval(function(){
              num2--;
              gain2.innerHTML = num2;

            
              if (num2== 0) {
                  clearInterval(timer2);
                  gain2.innerHTML = '重新发送';
                  gain2.disabled = false;
                  num2 = 60;
              } 
              },1000)

          }else{
            // alert(res.message);
            this.$message.warning(res.message)
          }
        },
  },
  watch:{
    async islimiadd(newval){
      //console.log('---',newval);
      this.limisadd = newval;
      let res = await api.rolelist(this.srccid);     
      this.rolelist = res.data.rolelist;
    },
    src_cid(newval){
      this.srccid = newval;
    }
  },
  beforeDestroy() {
      // 页面关闭（路由跳转）时清除定时器
      clearInterval(this.timer2)
      this.timer2 = null;
      this.num2 = 60;
    },
};
</script>

<style lang="less" scoped>
.el-checkbox{
    width: 160px;
    border: 0;
    padding-left: 0;
    margin-bottom: 10px;
}

.drag-item {
  margin-bottom: 10px;
}
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
  //   background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 260px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.name {
  margin-top: 10px;
  margin-left: 33px;
}
.label1,
.label2,
.label3 {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.label1 {
  margin-right: 48px;
}
.label2 {
  margin-right: 20px;
}
.label3 {
  float: left;
  margin-top: 30px;
  margin-right: 15px;
}
input {
  width: 260px;
  height: 32px;
  box-sizing: border-box;
  padding-left: 12px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  outline: none;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  color: #c0c4cc;
}
input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.xing,
.xing1 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 15px;
}
.xing1 {
  margin-top: 30px;
  margin-left: -5px;
}
.choice {
  float: left;
  width: 400px;
  margin-bottom: 44px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 106px;
  margin-top: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
#gain2 {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  color: #2A3346;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
