<template>
  <div class="splitbox">
    <div class="main">
      <div class="splitnav">
        <div class="splitit">{{zdname1}} - 账单导出</div>
      </div>

      <div class="xuanbox">
        <span class="xuantime">日期选择</span>
        <el-date-picker
          v-model="value1"
          value-format="YYYY-MM-DD"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <p class="tishi">提示:不选择默认上个月，也可以按实际日期选择</p>
      </div>

      <div class="splitbtn">
        <button class="xiao" @click="close">关闭</button>
        <button class="save" @click="qure">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
export default {
  props: ["zdname", "zdcid"],
  data() {
    return {
      zdname1:this.zdname,
      zdcid1:this.zdcid,
      value1: "",
    };
  },

  methods: {
    close() {
      this.$emit("zguanbi");
    },
    async zd(cid,name)
    {
      this.zdcid = cid;
      this.zdname = name;
      this.zdshow = true;
      // ElMessageBox.confirm("确认是否导出上个月账单", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     window.open('https://www-test.nituowola.com/site/x08081?cid='+cid,'_blank')
      //   })
      //   .catch(() => {
      //     this.$message.info("取消导出");
      //   });
 
    },
    qure() {
      if(this.value1 == '')
      {
        ElMessageBox.confirm("是否确认导出上月账单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            window.open('https://www-test.nituowola.com/site/x08081?cid='+this.zdcid1,'_blank')
          })
          .catch(() => {
            this.$message.info("取消导出");
          });
      }else{
        let st = this.value1[0];
        let et = this.value1[1];
        ElMessageBox.confirm("是否确认导出"+st+'到'+et+"账单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            window.open('https://www-test.nituowola.com/site/x08081?cid='+this.zdcid1+'&st='+st+'&et='+et,'_blank')
          })
          .catch(() => {
            this.$message.info("取消导出");
          });
      }

    },
  },
};
</script>

<style lang="less" scoped>
.splitbox {
  float: left;
  width: 1920px;
  height: 940px;
  background: #000000;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  position: absolute;
  top: -80px;
  left: -192px;
  z-index: 70;
}
.main {
  width: 520px;
  //   height: 310px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  padding: 28px;
  box-sizing: border-box;
}
.splitnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.splitit {
  font-size: 20px;
  color: #171746;
  font-weight: bold;
}

.splitbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
  margin-left: 20px;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
.xuanbox {
  margin-top: 20px;
}
.xuantime {
  font-size: 14px;
  color: #44444f;
  margin-right: 20px;
}
.xuanbox /deep/ .el-range-editor.el-input__inner {
  width: 350px;
}
.tishi {
  text-align: center;
  font-size: 14px;
  color: #b5b5be;
  margin-top: 20px;
}
</style>
