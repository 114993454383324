<template>
  <div class="exceptionbox">
    <img
      class="guanimg"
      src="../../src/assets/img/index/guanbi.png"
      alt=""
      @click="close"
    />
    <div class="exceptiontop">
      <!-- 订单数据异常 -->
      <div class="shujuexcept">
        <div class="shujutop">
          <div class="shujutit">订单数据异常</div>
          <div class="shujuqie">
            <i
              @click="shujubtn(3)"
              :class="[shujuqienum == 3 ? 'newshuju' : 'shuju']"
              >3月</i
            >
            <i
              @click="shujubtn(2)"
              :class="[shujuqienum == 2 ? 'newshuju' : 'shuju']"
              >2月</i
            >
            <i
              @click="shujubtn(1)"
              :class="[shujuqienum == 1 ? 'newshuju' : 'shuju']"
              >1月</i
            >
            <i
              @click="shujubtn(20)"
              :class="[shujuqienum == 20 ? 'newshuju' : 'shuju']"
              >20天</i
            >
            <i
              @click="shujubtn(10)"
              :class="[shujuqienum == 10 ? 'newshuju' : 'shuju']"
              >10天</i
            >
            <i
              @click="shujubtn(5)"
              :class="[shujuqienum == 5 ? 'newshuju' : 'shuju']"
              >5天</i
            >
          </div>
        </div>

        <div class="shujumain">
          <el-table :data="tableData" width="100%" height="92%" stripe>
            <el-table-column prop="company_name" label="合作商名称" width="290">
            </el-table-column>
            <el-table-column prop="last_time_t" label="最后一次订单时间" width="180">
            </el-table-column>
            <el-table-column prop="num" label="共计订单数"> </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 应收款异常 -->
      <div class="collection">
        <div class="shujutop">
          <div class="shujutit">应收款异常</div>
          <div class="shujuqie">
            <i
              @click="shoukuanbtn(1)"
              :class="[shoukuannum == 1 ? 'newshuju' : 'shuju']"
              >{{ tt1 }}</i
            >
            <i
              @click="shoukuanbtn(2)"
              :class="[shoukuannum == 2 ? 'newshuju' : 'shuju']"
              >{{ tt2 }}</i
            >
            <i
              @click="shoukuanbtn(3)"
              :class="[shoukuannum == 3 ? 'newshuju' : 'shuju']"
              >{{ tt3 }}</i
            >
          </div>
        </div>

        <div class="shujumain">
          <el-table :data="shoukuanData" width="100%" height="92%" stripe>
            <el-table-column prop="name" label="合作商名称" width="360">
            </el-table-column>
            <el-table-column prop="ys" label="应收" width="90">
            </el-table-column>
            <el-table-column label="已收">
              <template #default="scope">
                <i class="yitit">{{ scope.row.yf }}</i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 合作商 -->
      <div class="hezuoshang">
        <h2>合作商</h2>
        <div class="tubox" ref="tuchart"></div>
        <div class="hezuobottom">
          <div class="hezuoxinxi">
            <span class="xinxinum">{{num3[0]}}</span>
            <span class="xinxitit">禁用</span>
          </div>
          <div class="hezuoxinxi">
            <span class="xinxinum2">{{num3[1]}}</span>
            <span class="xinxitit2">本月新增</span>
          </div>
          <div class="hezuoxinxi">
            <span class="xinxinum">{{num3[2]}}</span>
            <span class="xinxitit">上月新增</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单数据 -->
    <div class="exceptionbottom">
      <div class="bottomtop">
        <div class="xuanxiang">
          <span class="xuantit">订单数据</span>
          <el-select filterable v-model="value" placeholder="可选择合作商进行查询" @change="changecid">
            <el-option

              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="xuanqie">
          <i @click="xuanbtn(1)" :class="[xuannum == 1 ? 'newshuju' : 'shuju']"
            >按年</i
          >
          <i @click="xuanbtn(2)" :class="[xuannum == 2 ? 'newshuju' : 'shuju']"
            >按月</i
          >
          <i @click="xuanbtn(3)" :class="[xuannum == 3 ? 'newshuju' : 'shuju']"
            >按日</i
          >
        </div>
      </div>
      <div class="xuantu" ref="xuanbiao"></div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
import * as api from "../api/car.js";
export default {
  data() {
    return {
      tt1:'',
      tt2:'',
      tt3:'',
      shujuqienum: 1,
      shoukuannum: 3,
      xuannum: 3,
      options: [
      ],
      value: "",
      tableData: [
      ],
      shoukuanData: [
      ],
      datalist: [
      ],
      dataname: "",
      linenum:[1,2,3,4,5,6,7,8],
      num7:[1,2,3,4,5,6,7],
      num3:[1,2,3],
      total:252,
      data1:[],
      data2:[],
      data3:[],
      data5:[],
      data10:[],
      data20:[],
      date11:[],
      date22:[],
      date33:[],
      x1:[],
      x2:[],
      x3:[],
      l11:[],
      l21:[],
      l31:[]
    };
  },
  created(){
      let nowmonth = new Date().getMonth() + 1;
      if(nowmonth > 3)
      {
        this.tt3 = (nowmonth - 1)+'月'
        this.tt2 = (nowmonth - 2)+'月'
        this.tt1 = (nowmonth - 3)+'月'
      }else if(nowmonth == 3)
      {
        this.tt3 = (nowmonth - 1)+'月'
        this.tt2 = (nowmonth - 2)+'月'
        this.tt1 = '12月'
      }else if(nowmonth == 2)
      {
        this.tt3 = (nowmonth - 1)+'月'
        this.tt2 = '12月'
        this.tt1 = '11月'
      }else if(nowmonth == 1)
      {
        this.tt3 = '12月'
        this.tt2 = '11月'
        this.tt1 = '10月'
      }

      this.getsp();
      
  },
  methods: {
    async changecid(){
      console.log(this.value);
      let res = await api.getspmore(this.value);
      let x= res.data;
      this.x1 = x.x1
      this.x2 = x.x2
      this.x3 = x.x3
      this.l11 = x.l11
      this.l21 = x.l21
      this.l31 = x.l31

      if(this.xuannum == 3)
      {
        this.datalist = this.x1
        this.linenum = this.l11
      }else if(this.xuannum == 2)
      {
        this.datalist = this.x2
        this.linenum = this.l21
      }else if(this.xuannum == 1)
      {
        this.datalist = this.x3
        this.linenum = this.l31
      }

      this.danexception();
    },
    async getsp(){
      let res = await api.getsp();
      let x= res.data;
      this.total = x.total
      this.num7 = x.num7
      this.num3 = x.num3
      this.data1 = x.data1
      this.data2 = x.data2
      this.data3 = x.data3
      this.data5 = x.data5
      this.data10 = x.data10
      this.data20 = x.data20
      this.date11 = x.date11
      this.date22 = x.date22
      this.date33 = x.date33
      this.x1 = x.x1
      this.x2 = x.x2
      this.x3 = x.x3
      this.l11 = x.l11
      this.l21 = x.l21
      this.l31 = x.l31
      this.options = x.option;

      if(this.shujuqienum == 1)
      {
        this.tableData = this.data1;
      }else if(this.shujuqienum == 2){
        this.tableData = this.data2;
      }else if(this.shujuqienum == 3){
        this.tableData = this.data3;
      }

      if(this.shoukuannum == 3)
      {
        this.shoukuanData = this.date11;
      }else if(this.shoukuannum == 2)
      {
        this.shoukuanData = this.date22;
      }else if(this.shoukuannum == 1)
      {
        this.shoukuanData = this.date33;
      }

      if(this.xuannum == 3)
      {
        this.datalist = this.x1
        this.linenum = this.l11
      }else if(this.xuannum == 2)
      {
        this.datalist = this.x2
        this.linenum = this.l21
      }else if(this.xuannum == 1)
      {
        this.datalist = this.x3
        this.linenum = this.l31
      }


      this.hezuoshang();
      this.danexception();
    },
    shujubtn(e) {
      this.shujuqienum = e;
      if(this.shujuqienum == 1)
      {
        this.tableData = this.data1;
      }else if(this.shujuqienum == 2){
        this.tableData = this.data2;
      }else if(this.shujuqienum == 3){
        this.tableData = this.data3;
      }else if(this.shujuqienum == 5){
        this.tableData = this.data5;
      }else if(this.shujuqienum == 10){
        this.tableData = this.data10;
      }else if(this.shujuqienum == 20){
        this.tableData = this.data20;
      }
    },
    shoukuanbtn(e) {
      this.shoukuannum = e;
      if(this.shoukuannum == 3)
      {
        this.shoukuanData = this.date11;
      }else if(this.shoukuannum == 2)
      {
        this.shoukuanData = this.date22;
      }else if(this.shoukuannum == 1)
      {
        this.shoukuanData = this.date33;
      }
    },
    xuanbtn(e) {
      this.xuannum = e;
      if(this.xuannum == 3)
      {
        this.datalist = this.x1
        this.linenum = this.l11
      }else if(this.xuannum == 2)
      {
        this.datalist = this.x2
        this.linenum = this.l21
      }else if(this.xuannum == 1)
      {
        this.datalist = this.x3
        this.linenum = this.l31
      }
      this.danexception();

    },
    close() {
      this.$emit("guanbi");
    },
    hezuoshang() {
      let myChart = echarts.init(this.$refs.tuchart);
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        title: {
          text: this.total,
          left: "19%",
          top: "50%",
          textStyle: {
            color: "#333",
            fontWeight: "bold",
            fontSize: 30,
          },
        },
        legend: {
          orient: "vertical",
          right: "15%",
          y: "30%",
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["25%", "55%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.num7[0], name: "保险公司及三方平台" },
              { value: this.num7[1], name: "修理厂" },
              { value: this.num7[2], name: "4S店" },
              { value: this.num7[3], name: "4S店售后" },
              { value: this.num7[4], name: "集团公司" },
              { value: this.num7[5], name: "汽贸" },
              { value: this.num7[6], name: "其他" },
            ],
          },
        ],
      });
    },
    danexception() {
      let myChart = echarts.init(this.$refs.xuanbiao);
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        color: ["red"],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          name: this.dataname,
          type: "category",
          boundaryGap: false,
          data: this.datalist,
        },
        yAxis: {
          type: "value",
          name: "数量",
        },
        series: [
          {
            type: "line",
            smooth: true,
            data:this.linenum
          },
        ],
      });
    },
  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
.exceptionbox {
  width: 1690px;
  height: 840px;
  background-color: #fafafb;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 10;
  padding: 20px 10px 10px;
}
.exceptiontop {
  display: flex;
  justify-content: space-between;
}
.shujuexcept,
.collection,
.hezuoshang {
  background-color: #fff;
  border-radius: 10px;
  padding: 19px;
  box-sizing: border-box;
  height: 487px;
  position: relative;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
}
h2 {
  position: absolute;
  top: 19px;
  font-size: 20px;
}
.shujuexcept {
  width: 610px;
}
.collection {
  width: 580px;
}
.hezuoshang {
  width: 460px;
}
.shujutop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.shujutit {
  font-size: 20px;
  font-weight: bold;
}
.shujuqie,
.xuanqie {
  border: 1px solid #e0e0e0;
  display: flex;
  border-radius: 8px;
}
i {
  font-style: normal;
}
.newshuju {
  display: flex;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  background-color: #2c68ff;
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
}
.shuju {
  display: flex;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.shuju:hover {
  background-color: #2c68ff;
  color: #fff;
}
.shujumain {
  height: 450px;
}
.shujumain /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
}
.yitit {
  color: #ff525c;
  font-style: normal;
}
.tubox {
  width: 490px;
  height: 350px;
}
.hezuobottom {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
}
.hezuoxinxi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.xinxinum,
.xinxinum2 {
  font-size: 30px;
}
.xinxinum {
  color: #888;
}
.xinxinum2 {
  color: #000;
}
.xinxitit,
.xinxitit2 {
  font-size: 14px;
  color: #888;
}
.xinxitit2 {
  color: #000;
}
.exceptionbottom {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 19px 19px 0;
  box-sizing: border-box;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
}
.bottomtop {
  display: flex;
  justify-content: space-between;
}
.xuantit {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.xuanxiang {
  display: flex;
  align-items: center;
}
.xuanxiang /deep/ .el-select .el-input {
  width: 300px !important;
}
.xuantu {
  width: 1652px;
  height: 250px;
}
.guanimg {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 10;
  cursor: pointer;
}
</style>
