<template>
  <div>
    <!-- 添加合作单位 -->
    <div class="box" v-show="sites">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>添加合作单位</h1>
        </div>
        <!-- 合作单位 -->
        <div class="append-name">
          <div class="xing">*</div>
          <label class="label1" for="">合作单位</label>
          <input type="text" v-model="name" placeholder="请输入合作单位名称" />
        </div>
        <!-- 公司简称 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">公司简称</label>
          <input type="text" v-model="short" placeholder="请输入简称" />
        </div>
        <!-- 公司类型 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">公司类型</label>
          <el-select
            v-model="this.companytype"
            placeholder="请选择公司类型"
            @change="changecarstyle"
            style="width: 280px"
          >
            <el-option
              v-for="item in typelist"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
              {{ item.type_name }}
            </el-option>
          </el-select>
        </div>
        <!-- 车商品牌 -->
        <div class="append-name" v-if="showbrand">
          <div class="xing"></div>
          <label class="label1" for="">车商品牌</label>
          <input type="text" v-model="brand" placeholder="请输入车商品牌" />
        </div>
        <!-- 联系人 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">联系人&#12288;</label>
          <input type="text" v-model="contact" placeholder="请输入联系人" />
        </div>
        <!-- 联系电话 -->
        <div class="append-name">
          <div class="xing">*</div>
          <label class="label1" for="">联系电话</label>
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            v-model="tel"
            placeholder="请输入联系电话"
          />
        </div>
        <!-- 联系地址 -->
        <div class="append-name">
          <div class="xing1">*</div>
          <label class="label1" for="">联系地址</label>
          <input
            v-model="addressC"
            class="accident-input"
            @blur="blurC"
            type="text"
            autocomplete="off"
            placeholder="请输入地址并在下拉框选中"
          />
          <div class="select-panel" v-show="isShowC">
            <ul>
              <li
                class="der"
                v-for="(item, index) in wordsC"
                :key="index"
                @click="mapC(index)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <input class="input-norm1" type="hidden" v-model="addressLnglatC" />
        <!-- 备注 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">备注&#12288;&#12288;</label>
          <el-input
            class="remark-txt"
            v-model="textarea1"
            autosize
            type="textarea"
            placeholder="请输入备注信息"
          />
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">业务归属</label>
          <el-select
            v-model="this.saleman"
            placeholder="请选择业务员"
            style="width: 280px"
          >
            <el-option
              v-for="item in saleslist"
              :key="item.uid"
              :label="item.nickname"
              :value="item.uid"
            >
              {{ item.nickname }}
            </el-option>
          </el-select>
        </div>
        <!-- 开票信息 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">开票信息</label>
          <el-input
            class="remark-txt"
            type="textarea"
            placeholder="请输入开票信息"
            v-model="piao"
          />
        </div>


        <div class="append-name appjia">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="需要回访">
              <el-radio v-model="huixuan" label="1">是</el-radio>
              <el-radio v-model="huixuan" label="0">否</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="联系车主">
              <el-radio v-model="contactxuan" label="1">必须</el-radio>
              <el-radio v-model="contactxuan" label="0">非必须</el-radio>
            </el-form-item>
          </el-form>
        </div>
           <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="车型价格">
              <el-radio v-model="carprice" label="1">启用</el-radio>
              <el-radio v-model="carprice" label="0">停用</el-radio>
            </el-form-item>
          </el-form>
        </div>
      <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="结算方式">
              <el-radio v-model="plancal" label="1">规划公里</el-radio>
              <el-radio v-model="plancal" label="0">实际公里</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="默认拖车">
              <el-radio v-model="isrescue" label="1">道路救援</el-radio>
              <el-radio v-model="isrescue" label="2">整车运输</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name" v-if="companytype == 19">
          <div class="xing"></div>
          <label class="label1" for="">归属集团</label>
          <el-select
            v-model="groupid"
            placeholder="请选择集团主公司"
            @change="changegropu"
            style="width: 280px"
          >
            <el-option
              filterable
              v-for="item in grouplist"
              :key="item.cid"
              :label="item.name"
              :value="item.cid"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
        <div class="append-name" v-if="showAi">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="智能识别">
              <el-radio v-model="ocrcarnumber" :label="1" @click.prevent="aa(ocrcarnumber)">车牌识别</el-radio>
              <el-radio v-model="ocrcarvin"    :label="1" @click.prevent="bb(ocrcarvin)" >车架识别</el-radio>
            </el-form-item>
          </el-form>
        </div>

        <div class="btn">
          <el-button class="set" :plain="true" @click="save">保存</el-button>
          <!-- <button @click="save" class="set">保存</button> -->
          <button @click="close" class="cancel">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/join";
import { reactive, ref } from "vue";
import { joinAdd } from "../api/join";
import * as api1 from "../api/index";
import phonebar from "jssip-emicnet/dist/phonebar";
const textarea1 = ref("");

const formInline = reactive({
  user: "",
  region: "",
});

export default {
  props: ["issite"],
  data() {
    return {
      //options,
      formInline,
      textarea1,
      sites: this.issite,
      isShowC: false,
      addressC: "",
      addressLnglatC: "",
      wordsC: [],
      newvalsC: "",
      oldaddressLnglatC: "",
      contact: "",
      tel: "",
      name: "",
      hzshua: 0,
      short: "",
      companytype: "",
      brand: "",
      piao: "",
      showbrand: false,
      typelist: [],
      saleman: "",
      saleslist: "",
      plancal:'0',
      huixuan: "0",
      contactxuan: "0",
      carprice:'0',
      groupid:'',
      grouplist:[],
      ocrcarnumber:0,
      ocrcarvin:0,
      showAi:false,
      isrescue:'2'
    };
  },
  methods: {
    close() {
      this.sites = false;
      // this.site = !this.site;
      this.textarea1 = "";
      Object.assign(this.$data, this.$options.data()); //清空所有内容
    },
    aa(value)
    {
      this.ocrcarnumber = value == 1?0:1;
    },
    bb(value)
    {
      this.ocrcarvin = value == 1?0:1;
    },
    async save() {
      if (this.name == "") {
        // alert('请输入合作商名称');
        this.$message.warning("请输入合作商名称");
        return false;
      }
      if (this.tel == "") {
        // alert('请输入联系电话');
        this.$message.warning("请输入联系电话");
        return false;
      } else if (/^[0-9][0-9- ]{6,10}[0-9]$/.test(this.tel) !== true) {
        this.$message.warning("请输入正确手机号码");
      }
      let res = await api.joinAdd(
        this.name,
        this.addressC,
        this.tel,
        this.contact,
        this.addressLnglatC,
        this.textarea1,
        this.short,
        this.companytype,
        this.brand,
        this.piao,
        this.saleman,
        this.huixuan,
        this.contactxuan,
        this.carprice,
        this.groupid,
        this.plancal,
        this.ocrcarnumber,
        this.ocrcarvin,
        this.isrescue
      );
      if (res.code == 1) {
        this.$message.success("保存成功");
        this.hzshua++;
        this.$emit("datas", this.hzshua);
        this.sites = false;
      } else {
        // alert(res.message);
        this.$message.warning(res.message);
      }
    },
    changecarstyle() {
      if (
        this.companytype == 4 ||
        this.companytype == 8 ||
        this.companytype == 9
      ) {
        this.showbrand = true;
      } else {
        this.showbrand = false;
      }
    },
    blurC() {
      setTimeout(() => {
        this.isShowC = false;
        //console.log("失去焦点----");
      }, 200);
    },
    async searchC(newval) {
      this.newvalsC = newval;
      this.addressLnglatC = "";
      let res = await api1.workMap(this.newvalsC);
      this.wordsC = res.data;
      if (this.wordsC.length > 0) {
        this.isShowC = true;
      } else {
        this.isShowC = false;
      }
    },
    mapC(query) {
      this.oldaddressLnglatC = this.wordsC[query].value;
      this.addressC = this.wordsC[query].value;
      this.addressLnglatC = this.wordsC[query].location;
      this.isShowC = false;
    },
    async getcompanytype() {
      this.typelist = [];
      let res = await api1.getcompanytype();
      this.typelist = res.data;
      this.saleslist = [];
      this.saleman = "";
      let res1 = await api1.getsaleslist();
      this.saleslist = res1.data;
      let res2 = await api1.getgrouplist();
      this.grouplist = res2.data;
      this.showAi= res2.showAi;
    },
  },
  watch: {
    issite(newval) {
      console.log("---", newval);
      this.sites = newval;
      this.getcompanytype();
    },
    addressC(newval) {
      console.log("------", newval);
      if (newval == this.oldaddressLnglatC) {
        this.isShowC = false;
        return;
      }
      this.addressLnglatC = "";
      if (newval == "") {
        this.isShowC = false;
        return false;
        // this.searchC(newval);
      }
      if (this.addressLnglatC == "") {
        this.searchC(newval);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  // background-color: #fff;
  z-index: 11;
}
.select-panel {
  // height: 100px;
  position: absolute;
  margin-left: 77px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: 70px;
  margin-left: 0px;
}
.box-main {
  width: 600px;
  height: 920px;
  //   background: orange;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 170px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  padding-top: 21px;
  padding-left: 30px;
  opacity: 1;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 117px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
  vertical-align: top;
}

.remark-txt {
  width: 350px;
  height: 40px;
  // margin-left: 20px;
}
.remark-label {
  width: 70px;
  margin-right: 20px;
  line-height: 40px;
  font-size: 14px;
  display: inline-block;
  box-sizing: border-box;
  // margin-left: 8px;
}
.site {
  margin-left: 33px;
  margin-top: 12px;
  font-size: 14px;
}
.site-label {
  width: 70px;
  display: inline-block;
  margin-right: 20px;
}
.site-input {
  margin-left: 115px;
  margin-top: 12px;
}
.append-name,
.append-short,
.append-type,
.append-phone,
.contact,
.site,
.remark {
  width: 500px;
  height: 40px;
  opacity: 1;
}

.append-name {
  margin-top: 10px;
  margin-left: 30px;
}
.append-phone {
  margin-top: 12px;
  margin-left: 40px;
}
.append-short {
  margin-top: 12px;
  margin-left: 33px;
}
.append-type {
  margin-top: 12px;
  margin-left: 33px;
}
.box-main /deep/ .el-select .el-input {
  width: 350px;
  height: 32px;
}

.contact {
  margin-top: 12px;
  margin-left: 33px;
}
.remark {
  margin-top: 12px;
  margin-left: 37px;
}
.label1,
.label2 {
  // width: 70px;
  display: inline-block;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  box-sizing: border-box;
  margin-right: 20px;
}
// .label1 {
//   margin-right: 20px;
// }
// .label2 {
//   margin-right: 34px;
// }
input {
  width: 350px;
  height: 32px;
  padding-left: 7px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  outline: none;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
input:hover {
  border: 1px solid #c0c4cc;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.xing,
.xing1 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 15px;
}
.xing1 {
  margin-top: 15px;
}
.appjia{
  margin-top: 20px;
}
.append-name /deep/ .el-form-item__label {
  margin-right: 20px;
  padding: 0;
  box-sizing: border-box;
}
</style>
