<template>
  <div>
    <!-- 营销方案 -->
    <div class="programBox" v-show="xiaos">
      <img
        @click="close"
        class="cha"
        src="../assets/img/ding/icon-close.png"
        alt=""
      />
      <div class="jiacarmain">
        <div class="jiacarNav">
          <h1 class="jiacarNavTit">营销方案</h1>
        </div>
        <!-- 合作单位 -->
        <div class="jiaStyle">
          <label class="carStyle" for="">合作单位:</label>
          <input class="leixing" type="text" disabled v-model="name" />
        </div>
        <!-- 账户余额 -->
        <div class="yuBox">
          <label class="yuTit" for="">账户余额:</label>
          <span class="yuNum">{{account}}</span>
        </div>
        <!-- 营销方案选择 -->
        <div class="xuan">
          <i class="xing">*</i>
          <label class="xuanTit" for="">营销方案选择：</label>
          <el-checkbox-group v-model="checkList" @change="funXian">
            <el-checkbox value="1" label="1">距离优惠</el-checkbox>
            <el-checkbox value="2" label="2">充值满送</el-checkbox>
            <el-checkbox value="3" label="3">订单产值返</el-checkbox>
            <el-checkbox value="4" label="4">类型优惠</el-checkbox>
            <el-checkbox value="5" label="5">超公里价</el-checkbox>
          </el-checkbox-group>

          <div class="yin">
            <!-- 距离优惠 -->
            <div class="yinbox" v-show="over">
              <div class="juliTit">
                <label class="juliNav" for="">优惠类型:</label>
                <el-select
                  style="width:80px;float:left;"
                  v-model="type"
                  placeholder="类型"
                  @change="changerole"
                >
                <el-option label="即时" value="1"></el-option>
                <el-option label="预约" value="2"></el-option>
                <el-option label="顺路" value="3"></el-option>
              </el-select>
                <div class="shuBox">
                  <div class="shu" style="margin-right:5px">
                   
                    <input
                      class="juliNum"
                      v-model="li"
                      type="text"
                      placeholder=">="
                    />
                    <span class="gongli">公里</span>
                  </div>
                  <div class="shu">
                    <input
                      class="juliNum"
                      v-model="zhe"
                      type="text"
                      placeholder="0~1"
                      oninput="value=value.replace(/^([^01]|(0(\.\d{2}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
                    />
                    <span class="zhe"></span>
                  </div>
                  <img
                    class="jiaImg"
                    src="../assets/img/cd/jiahao.png"
                    alt=""
                    @click="add"
                  />
                </div>

                <div class="shumain">
                  <ul class="shuUl" v-show="type == 1">
                    <li v-for="(item, index) in list1" :key="index" >
                      <span class="shutit">
                      {{item.type==1?'即时':(item.type ==2?'预约':'顺路')}}:'>='{{item.km }}公里 {{ item.discount}}
                      </span>
                      <img
                        class="cuo"
                        @click="chu(index,item.type)"
                        src="../assets/img/cd/cuo.png"
                        alt=""
                      />
                    </li>
                    </ul>
                    <ul class="shuUl" v-show="type == 2">
                     <li v-for="(item1, index) in list2" :key="index">
                      <span class="shutit">
                      {{item1.type==1?'即时':(item1.type ==2?'预约':'顺路')}}:'>='{{item1.km }}公里 {{ item1.discount}}
                      </span>
                      <img
                        class="cuo"
                        @click="chu(index,item1.type)"
                        src="../assets/img/cd/cuo.png"
                        alt=""
                      />
                    </li>
                    </ul>
                    <ul class="shuUl" v-show="type == 3">
                     <li v-for="(item2, index) in list3" :key="index">
                      <span class="shutit">
                      {{item2.type==1?'即时':(item2.type ==2?'预约':'顺路')}}:'>='{{item2.km }}公里 {{ item2.discount}}
                      </span>
                      <img
                        class="cuo"
                        @click="chu(index,item2.type)"
                        src="../assets/img/cd/cuo.png"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
             <div class="yinbox" v-show="over4">
              <div class="juliTit">
                <label class="juliNav" for="">优惠类型:</label>
                <el-select
                  style="width:80px;float:left;"
                  v-model="type"
                  placeholder="类型"

                  @change="changerole"
                >
                <el-option label="预约" value="2"></el-option>
                <el-option label="顺路" value="3"></el-option>
              </el-select>
                <div class="shuBox">
                  <div class="shu">
                    <input
                      class="juliNum"
                      v-model="zhe"
                      type="text"
                      placeholder="0~1"
                      oninput="value=value.replace(/^([^01]|(0(\.\d{2}.+))|(1\.[^0]+)|(1\.00.+)|([01][^\.]))$/,'')"
                    />
                    <span class="zhe"></span>
                  </div>
                  <img
                    class="jiaImg"
                    src="../assets/img/cd/jiahao.png"
                    alt=""
                    @click="add4"
                  />
                </div>

                <div class="shumain">
                  <ul class="shuUl">
                    <li v-for="(item, index) in list4" :key="index" >
                      <span class="shutit">
                      {{item.type==1?'即时':(item.type ==2?'预约单':'顺路单')}}:{{ item.discount}}
                      </span>
                      <img
                        class="cuo"
                        @click="chu4(index)"
                        src="../assets/img/cd/cuo.png"
                        alt=""
                      />
                    </li>
                    </ul>
                </div>
              </div>
            </div>
            <div class="yinbox" v-show="over5">
              <div class="juliTit">
                <label class="juliNav" for="">超公里价:</label>
                <div class="shuBox">
                  <div class="shu">
                    <div style="float: left; width:15px; line-height:32px;">超</div>
                    <input
                      class="juliNum"
                      v-model="max_km"
                      type="number"
                      placeholder="N"
                    /><div style="float: left; width:70px; line-height:32px;margin-left:5px;">公里 全程按</div><input
                      class="juliNum"
                      v-model="max_per"
                      type="number"
                      placeholder="N"
                    /><div style="float: left; width:200px; line-height:32px; margin-left: 5px;">元/公里结算<span style="color:red">（有具体免拖数慎用）</span></div>

                  </div>

                </div>
              </div>
            </div>
            <!-- 充值满送 -->
            <div class="yinbox2" v-show="over1">
              <div class="juliTit">
                <label class="juliNav" for="">充值满送:</label>
                <div class="shuBox">
                  <div class="shu">
                    <input
                      class="chongNum"
                      v-model="zhi"
                      type="text"
                      placeholder="请输入"
                    />
                    <span class="yuan">元</span>
                  </div>
                  <img
                    class="jiaImg"
                    src="../assets/img/cd/jiahao.png"
                    alt=""
                    @click="arr"
                  />
                </div>
                <dir class="chongBox">
                  <label class="chongTit" for="">操作历史:</label>
                  <div class="chongmain">
                    <ul class="shuUl">
                      <li v-for="(item, index) in lists" :key="index">
                        <span class="shutit">{{item.time}}-{{item.note}}{{item.money}}元 {{item.op_user}}</span>

                      </li>
                    </ul>
                  </div>
                </dir>
              </div>
            </div>

            <!-- 订单产值返 -->
            <div class="yinbox3" v-show="over2">
              <div class="juliTit">
                <label class="chanNav" for="">产值返:</label>
                <div class="shuBox">
                  <div class="shu">
                    <input
                      class="chongNum"
                      v-model="chan"
                      type="text"
                      placeholder="请输入"
                    />
                    <span class="yuan">元</span>
                  </div>
                  <img
                    class="jiaImg"
                    src="../assets/img/cd/jiahao.png"
                    alt=""
                    @click="fan"
                  />
                </div>

                <dir class="chongBox">
                  <label class="chongTit" for="">操作历史:</label>
                  <div class="chongmain">
                    <ul class="shuUl">
                      <li v-for="(item, index) in listes" :key="index">
                        <span class="shutit">{{item.time}} 返值{{item.money}}元</span>
                      </li>
                    </ul>
                  </div>
                </dir>
              </div>
            </div>
          </div>

          <div class="btn">
            <button class="save" @click="submit">保存</button>
            <button class="xiao" @click="close">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tr } from "element-plus/lib/locale";
import * as api from "../api/join";
import { TreeOptionsEnum } from 'element-plus/es/components/tree-v2/src/virtual-tree';

export default {
  props: ["isxiao","commissioncid"],
  data() {
    return {
      checkList: [],
      over: false,
      over1: false,
      over2: false,
      over4: false,
      over5:false,
      you: false,
      chong: false,
      ding: false,
      xiaos: this.isxiao,
      ccid:'',
      name:'',
      account:0,
      li: "",
      zhe: "",
      zhi: "",
      chan: "",
      max_km:'',
      max_per:'',
      list1: [
      ],
      list2: [
      ],
      list3: [
      ],
      list4: [
      ],
      lists: [ 
      ],
      listes: [
      ],
      type:'',
    };
  },
  methods: {
    close() {
      this.xiaos = false;
    },

    add() {
      console.log("输入框的值++++", this.li, this.zhe);
      if (this.li != "" && this.zhe != "" && this.type !='') {

        if(this.type == 1){
            let ret = this.list1.find((ev)=>{
            return ev.km == this.li;
            })

            if(ret != undefined)
            {
              this.$message.warning('已存在同样公里数的折扣');
              return;
            }

        }else if(this.type == 2)
        {
            let ret = this.list2.find((ev)=>{
              return ev.km == this.li;
            })
            if(ret != undefined)
            {
              this.$message.warning('已存在同样公里数的折扣');
              return;
            }

        }else if(this.type == 3)
        {
            let ret = this.list3.find((ev)=>{
            return ev.km == this.li;
            })

          if(ret != undefined)
          {
            this.$message.warning('已存在同样公里数的折扣');
            return;
          }
        }



        let obj = {
          type:this.type,
          km: this.li,
          discount: this.zhe
        }
        if(this.type == 1)
        {
          this.list1.push(obj);
        }else if(this.type == 2)
        {
          this.list2.push(obj);
        }else if(this.type == 3)
        {
          this.list3.push(obj);
        }
        this.li = "";
        this.zhe = "";
      } else {
        this.$message.warning("请输入");
      }
    },

     add4() {
      console.log("输入框的值++++", this.li, this.zhe);
      if ( this.zhe != "" && this.type !='') {


        let ret = this.list4.find((ev)=>{
          return ev.type == this.type;
        })
        if(ret != undefined)
        {
          this.$message.warning('已存在该订单类型的折扣');
          return;
        }

        let obj = {
          type:this.type,
          discount: this.zhe
        }

        this.list4.push(obj);
        this.zhe = "";
      } else {
        this.$message.warning("请输入");
      }
    },
    changerole()
    {
      console.log(this.type);
    },
    async arr() {
      console.log("输入框的值=====", this.zhi);
      if (this.zhi != "") {
        let res = await api.rechargeaccount(this.ccid,this.zhi,2);
        if(res.code == 1)
        {
          this.$message.success('成功');
          this.getinfo();
          this.zhi ='';
        }
      } else {
        this.$message.warning("请输入");
      }
    },
    async fan() {
      console.log("输入框的值=====", this.chan);
      if (this.chan != "") {
       let res = await api.rechargeaccount(this.ccid,this.chan,3);
        if(res.code == 1)
        {
          this.$message.success('成功');
          this.getinfo();
          this.chan = '';
        }
      } else {
        this.$message.warning("请输入");
      }
    },
    chu(index,type) {
      console.log("点击列表id------", index);
      if(type == 1)
      {
        this.list1.splice(index, 1);
      }else if(type == 2)
      {
        this.list2.splice(index, 1);
      }else if(type == 3)
      {
        this.list3.splice(index, 1);
      }
    },
    chu4(index) {
      console.log("点击列表id------", index);
      this.list4.splice(index, 1);
    },
    chus(index) {
      this.lists.splice(index, 1);
    },
    chues(index) {
      this.listes.splice(index, 1);
    },
    funXian() {
      if(this.checkList.indexOf("1") !== -1 && (this.checkList.indexOf("4") !== -1 || this.checkList.indexOf("5") !== -1))
      {
         this.$message.warning('距离优惠与类型优惠/超公里价不能共存');
         if(this.over == true)
         {
            this.checkList.splice(this.checkList.indexOf("4"), 1);
         }else if(this.over4 == true){
            this.checkList.splice(this.checkList.indexOf("1"), 1);

         }else if(this.over5 == true)
         {
            this.checkList.splice(this.checkList.indexOf("1"), 1);
         }
         return false;
      }

      if(this.checkList.indexOf("4") !== -1 && this.checkList.indexOf("5") !== -1)
      {
        this.$message.warning('类型优惠与超公里价不能共存');
          if(this.over4 == true){
            this.checkList.splice(this.checkList.indexOf("5"), 1);
         }else if(this.over5 == true)
         {
            this.checkList.splice(this.checkList.indexOf("4"), 1);
         }
         return false;        
      }
      if (this.checkList.indexOf("1") == -1) {
        this.over = false;
      } else {
        this.over = true;
      }
      if (this.checkList.indexOf("2") == -1) {
        this.over1 = false;
      } else {
        this.over1 = true;
      }
      if (this.checkList.indexOf("3") == -1) {
        this.over2 = false;
      } else {
        this.over2 = true;
      }
      if (this.checkList.indexOf("4") == -1) {
        this.over4= false;
      } else {
        this.over4 = true;
      }

      if (this.checkList.indexOf("5") == -1) {
        this.over5= false;
      } else {
        this.over5 = true;
      }
    },
    async submit()
    {
      let list = [];
      let a = list.concat(this.list1);
      let b = a.concat(this.list2);
      let c = b.concat(this.list3);

      let x = this.list4;

      if(this.checkList.indexOf("1") != -1 && c.length == 0)
      {
        this.$message.warning('请录入距离优惠的具体条目');
        return;
      }

      if(this.checkList.indexOf("4") != -1 && x.length == 0)
      {
        this.$message.warning('请输入具体的订单类型折扣');
        return;
      }

      if(this.checkList.indexOf("5") != -1 && (this.max_km == '' || this.max_per == ''))
      {
        this.$message.warning('请输入具体的公里数及单价');
        return;       
      }

      let z = [];


      if(this.max_km != '' && this.max_per != '')
      {
          z = {
            max_km:this.max_km,
            max_per:this.max_per
          };
      }

      //  console.log(z);
      //  return false;


      let res = await api.updatecommissionnew(this.ccid,this.checkList,c,x,z);
      if(res.code == 1)
      {
        this.$message.success('成功');
        this.getinfo();
      }

      return;


    },
    async getinfo(){
        let res = await api.partnercommissionnew(this.ccid);
        console.log(res);
        this.name = res.data.name;
        this.account = res.data.account;
        this.checkList = res.data.list;
        this.list1 = res.data.list1;
        this.list2 = res.data.list12;
        this.list3 = res.data.list13;
        this.list4 = res.data.list4;
        this.lists = res.data.list2;
        this.listes = res.data.list3;
        if (this.checkList.indexOf("1") == -1) {
          this.over = false;
        } else {
          this.over = true;
        }
        if (this.checkList.indexOf("2") == -1) {
          this.over1 = false;
        } else {
          this.over1 = true;
        }
        if (this.checkList.indexOf("3") == -1) {
          this.over2 = false;
        } else {
          this.over2 = true;
        }
         if (this.checkList.indexOf("4") == -1) {
          this.over4 = false;
        } else {
          this.over4 = true;
        }

        if (this.checkList.indexOf("5") == -1) {
          this.over5 = false;
          this.max_km  = '';
          this.max_per = '';
        } else {
          this.over5 = true;
          this.max_km = res.data.list5.max_km;
          this.max_per = res.data.list5.max_per;
        }
    },
  },
  watch: {
    isxiao(newval) {
      console.log("------", newval);
      this.xiaos = newval;
    },
    async commissioncid(newval){
      this.ccid = newval;
      this.getinfo();
    }
  },
};
</script>

<style lang="less" scoped>
.programBox {
  width: 640px;
  height: 920px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 55;
  box-shadow: 0px -5px 10px #e0e0e0;
}
.cha {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -71px;
}
.jiacarNav {
  height: 80px;
  width: 600px;
}
.jiacarNavTit {
  font-size: 20px;
  line-height: 80px;
  margin-left: 30px;
  font-weight: bold;
  color: #2a3346;
  padding-left: 30px;
  padding-top: 21px;
}
.jiaStyle {
  margin-top: 10px;
  margin-left: 74px;
}
.carStyle,
.yuTit,
.xuanTit,
.juliNav,
.chongTit,
.chanNav {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.juliNav {
  margin-left: 41px;
  float: left;
  margin-right: 8px;
  line-height: 32px;
}
.chanNav {
  float: left;
  margin-right: 8px;
  margin-left: 55px;
  line-height: 32px;
}
.chongTit {
  margin-left: 41px;
  float: left;
}
.xuanTit {
  float: left;
  line-height: 31px;
}
.leixing {
  width: 240px;
  height: 32px;
  border-radius: 4px;
  margin-left: 14px;
  padding-left: 12px;
  border: 1px solid #dcdfe6;
  outline: none;
}
.yuBox {
  margin-left: 74px;
  margin-top: 10px;
}
.yuNum {
  font-size: 16px;
  color: #ff0000;
  margin-left: 14px;
}
.xuan {
  margin-left: 35px;
  margin-top: 10px;
}
i {
  font-style: normal;
}
.xing {
  float: left;
  line-height: 31px;
  color: red;
  margin-right: 4px;
  vertical-align: middle;
}
.xuan /deep/ .el-radio-group {
  vertical-align: middle;
}

.yinbox,
.yinbox2,
.yinbox3 {
  float: left;
  // height: 100px;
  width: 500px;
  position: relative;
}
.yinbox {
  margin-top: 12px;
}
.yinbox2,
.yinbox3 {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
}
.juliNum {
  width: 60px;
  height: 32px;
  float:left;
  border: 1px solid #dcdfe6;
  padding-left: 5px;
  box-sizing: border-box;
  outline: none;
}
.shuBox {
  float: left;
  height: 32px;
  box-sizing: border-box;
}
.shu {
  float: left;
  margin-right: 10px;
}
.gongli,
.zhe,
.yuan {
  width: 38px;
  height: 30px;
  background: #fafafa;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
}
.gongli {

  border-left: 1px solid #dcdfe6;
}
.zhe {

  border-left: 1px solid #dcdfe6;
}
.yuan {
  position: absolute;
  top: 25px;
  left: 309px;
  border-left: 1px solid #d9d9d9;
}
// input[type="text"]:focus {
//   outline: none;
//   border-color: #409eff;
//   background-color: #fff;
// }
input:hover {
  border: 1px solid #c0c4cc;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.jiaImg {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.shumain {
  float: left;
  width: 320px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  margin-left: 108px;
  margin-top: 20px;
  overflow-y: scroll;
}
.shumain::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.chongBox {
  float: left;
  margin-top: 20px;
}
.chongmain {
  width: 320px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  margin-left: 108px;
  overflow-y: scroll;
}
.chongmain::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.chongNum {
  width: 240px;
  height: 32px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
}

.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.btn {
  margin-left: 76px;
  margin-top: 40px;
  float: left;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}

.cuo {
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
}
.shuUl {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.shuUl li {
  margin: 10px;
}
.shutit {
  vertical-align: middle;
}
.el-checkbox{
  margin-right:10px;
}
</style>