<template>
  <div>
    <div class="photoBox" v-if="zhaos">
      <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
      <div class="photomain">
        <h1>照片</h1>
        <table border="0" cellspacing="0" class="photobiao">
          <thead>
            <th class="project">救援项目</th>
            <th class="flow">救援流程</th>
            <th class="source">来源</th>
            <th class="example">示例图</th>
            <th class="operate">操作</th>
          </thead>
          <tbody class="photobody">
            <tr
              :class="
                (index % 2 == 0 && !changecolor) ||
                (index % 2 !== 0 && changecolor)
                  ? 'bg_color'
                  : 'bg_color2'
              "
               v-for="(data, index) in list" :key="index"
            >
              <td class="td-project">{{data.name}}</td>
              <td class="td-flow">{{data.lc}}</td>
              <td class="td-source">{{data.from}}</td>
              <td class="td-example">{{data.moren}}</td>
              <td class="td-operate">
                <i @click="shili(data.id)" class="photo">示例照片</i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <example :isshili="photoli" :exrid='exrrid' :exccid="photocid"></example>
  </div>
</template>

<script>
import example from "./example.vue";
import * as api from "../api/index";
export default {
  components: { example },
  props: ["isphoto","photoccid"],
  data() {
    return {
      zhaos: this.isphoto,
      photocid:this.photoccid,
      status: "1",
      photoli: 0,
      list:[],
      exrrid:''
    };
  },
  methods: {
    close() {
      this.zhaos = false;
    },
    shili(id) {
      this.photoli++;
      this.exrrid = id;
      if(this.photoli == 3) {
          this.photoli = 1
      }
    },
    async gettypelist()
    {
      this.list = [];
      let res = await api.getphoto(this.photocid);
      this.list = res.data;
      console.log('liebia',this.list);
    }
  },
  watch: {
    isphoto(newval) {
      this.zhaos = newval;
    },
    photoccid(newval)
    {
      this.photocid = newval;
      this.gettypelist();
    }
  },
};
</script>

<style lang="less" scoped>
.photoBox {
  width: 760px;
  height: 920px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
h1 {
  padding-top: 20px;
  padding-left: 30px;
  //   height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  box-sizing: border-box;
  opacity: 1;
}
i {
  font-style: normal;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin-right: 10px;
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.photobiao {
  width: 100%;
}
.photobiao td {
  text-align: center;
}
.photobody tr {
  height: 40px;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}
.know,
.photo {
  height: 22px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
.know1 {
  color: #ff505a;
  cursor: pointer;
}
</style>